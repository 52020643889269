import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
import { Switch, Spin } from "antd";
import axios from "axios";

const Notifications = () => {
  const initialState = {
    email: "",
    browser: "",
    update: false,
  };
  const [FormData, setFormData] = useState(initialState);
  const { email, browser, update } = FormData;

  const getNotifaction = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/` +
      localStorage.getItem("venderId");

    axios.get(link).then((response) => {
      if (response.data.success) {
        setFormData({
          ...FormData,
          browser: response.data.data.browser_notification,
          email: response.data.data.email_notification,
          update: true,
        });
      } else {
      }
    });
  };
  useEffect(() => {
    getNotifaction();
    // eslint-disable-next-line
  }, []);

  const emailChange = (e) => {
    setFormData({
      ...FormData,
      email: e,
    });
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/email_notification/` +
      localStorage.getItem("venderId");

    axios
      .put(link, {
        email_notification: e,
      })
      .then((response) => {
        if (response.data.success) {
        }
      });
  };

  const browserChange = (e) => {
    setFormData({
      ...FormData,
      browser: e,
    });
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/browser_notification/` +
      localStorage.getItem("venderId");

    axios
      .put(link, {
        browser_notification: e,
      })
      .then((response) => {
        if (response.data.success) {
        }
      });
  };

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-12 Orange border196 pt-5">
          <div className="col-11 mxAuto maxWidth">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt; Notifications
            </p>
            <h1 className="White  pb-3 font39">Notifications</h1>
          </div>
        </div>

        <div className="col-11 mxAuto pt-5 maxWidth">
          <div className="row">
            <div className="col-md-7 col-12 ">
              <div>
                <h3 className="font20">Messages</h3>
                <p className="font14">
                  Receive messages from hosts and guests, including booking
                  requests.
                </p>
                <div className="row">
                  <p className="mr-auto font17">Email</p>
                  <Switch
                    unCheckedChildren="Off"
                    checkedChildren="ON"
                    checked={email}
                    onChange={emailChange}
                    className="ml-auto"
                  />
                </div>
                <hr></hr>
                <div className="row">
                  <p className="mr-auto col-9 px-0 font17">
                    Browser notifications
                  </p>
                  <Switch
                    unCheckedChildren="Off"
                    checkedChildren="ON"
                    checked={browser}
                    onChange={browserChange}
                    className="ml-auto "
                  />
                  <p className="col-12 pr-0 pl-0 font14">
                    Receive notifications outside of your browser on either
                    desktop or mobile web
                  </p>
                </div>
                <hr></hr>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Notifications;
