import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import userImg from "../../assests/user.svg";

export default function ReviewsComponent(props) {
  const { review } = props;
  return (
    <div className="col-md-6 col-12">
      <div className="d-inline-flex ">
        <div className="mr-3">
          <Avatar src={userImg} />
        </div>
        <div className="">
          <p className="mb-0">{review.first_name + " " + review.last_name}</p>
          <p>{review.review}</p>
        </div>
      </div>
    </div>
  );
}
