import React, { useEffect, useState } from "react";
import headerimage from "../assests/headerimage.jpg";
import BlackNavbar from "./SubComponents/BlackNavbar";
import NewsRoomCards from "./SubComponents/NewsRoomCards";
import axios from "axios";
import { Spin } from "antd";
const Blogs = () => {
  const initialstate = {
    All: [],
    update: false,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [FormData, setFormData] = useState(initialstate);
  const { All, update } = FormData;
  const RenderNewsRoom = All.map((newsroom) => {
    return (
      <>
        <NewsRoomCards key={newsroom._id} newsroom={newsroom}></NewsRoomCards>
      </>
    );
  });
  const getAll = () => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/user/get_blogs`;

    axios.get(link).then((response) => {
      console.log(response);
      if (response.data.success) {
        setFormData({
          ...FormData,
          All: response.data.data,
          update: true,
        });
      } else {
        setFormData({
          ...FormData,
          update: true,
        });
      }
    });
  };
  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);
  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />

        <div
          className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
          style={{ backgroundImage: `url(${headerimage})` }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-11 mxAuto px-0">
                <div className="mask p-md-5 my-auto mxAuto ">
                  <div className="col-12  mxAuto mb-5 px-auto">
                    <h1 className="font58 White pt-5">Blogs</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" container-fluid col-10 mxAuto mt-5 maxWidth">
          {All.length === 0 ? (
            <h3 className="text-center">No Blogs Yet</h3>
          ) : (
            <div className="row col-12">{RenderNewsRoom}</div>
          )}
        </div>
      </>
    );
  }
};

export default Blogs;
