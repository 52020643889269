import React, { useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
import Payout from "../../API/Payouts/Payout";
import { message } from "antd";
const Payments = () => {
  let id = localStorage.getItem("venderId");
  const initialstate = {
    email: "",
    error: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  // eslint-disable-next-line
  const { email } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        Payout(id, email);
        setFormData({
          ...FormData,
          email: "",
        });
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid");
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required");
    }
  };
  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <div className="col-11 mxAuto">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt;Payouts
            </p>
            <h1 className="White  pb-3">Payouts</h1>
          </div>
        </div>
      </div>

      <div className="maxWidth">
        <div className="col-11 row mxAuto pt-3 px-4">
          <div className="col-12 col-md-5 ">
            <h1 className="font22">Payout Methods</h1>
            <div className="paddingTop_3">
              <input
                type="text"
                className="fillcolor rounded-0 col-12 p-2 bordercolor"
                placeholder="Enter Stripe Registered Email"
                name="email"
                value={email}
                onChange={onHandleChange}
              ></input>
            </div>
            <div className="pt-2 d-block mx-auto">
              <button
                type="button"
                className="btn Orange w-50 White rounded-0"
                onClick={validation}
              >
                Add Payout Method
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
