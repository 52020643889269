import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import marker from "../assests/marker.png";
import { BrowserRouter } from "react-router-dom";

class LiveLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lati: "",
      long: "",
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
    };
  }

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  displayMarkers = () => {
    // eslint-disable-next-line
    return this.props.store.map((stores, index) => {
      // eslint-disable-next-line
      if (this.props.wifi == false || stores.wifi == this.props.wifi) {
        // eslint-disable-next-line
        if (
          this.props.typeofVendor !== "deposit" ||
          stores.payment_type === this.props.typeofVendor
        ) {
          // eslint-disable-next-line
          if (
            this.props.travel === false ||
            stores.trevel_to_me === this.props.travel
          ) {
            return (
              <>
                <Marker
                  key={index}
                  label={index}
                  icon={{
                    url: marker,
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                  name={stores.business_information.city}
                  id={stores._id}
                  b_name={stores.business_information.business_name}
                  image={stores.images[0]}
                  onClick={this.onMarkerClick}
                  position={{ lat: stores.lat, lng: stores.lng }}
                ></Marker>
              </>
            );
          }
        }
      }
    });
  };

  render() {
    if (!this.props.loaded) return <div>Loading...</div>;
    console.log(this.props);
    return (
      <>
        <Map
          className="map"
          google={this.props.google}
          onClick={this.onMapClicked}
          initialCenter={{
            lat: this.props.originLat,
            lng: this.props.originLng,
          }}
          zoom={this.props.store.length !== 0 ? 13 : 7}
          scrollwheel={true}
        >
          {this.displayMarkers()}
          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
          >
            <BrowserRouter>
              <div className="col-12 infoWindow px-0 mxAuto">
                <a
                  href={"/saloonDetail/" + this.state.selectedPlace.id}
                  target="/blank"
                >
                  <div className="inff">
                    <img
                      src={this.state.selectedPlace.image}
                      alt=""
                      style={{ width: "100%" }}
                    ></img>
                    <p className="text-center title pt-2 mb-0 font18">
                      {this.state.selectedPlace.b_name}
                    </p>
                    <p className="text-center address mb-0 font16">
                      {this.state.selectedPlace.name}
                    </p>
                  </div>
                </a>
              </div>
            </BrowserRouter>
          </InfoWindow>
        </Map>
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyD2iP4IVdhlYOWZEjU6_j0qhP7juyuUKKg",
  version: "3.38",
})(LiveLocation);
