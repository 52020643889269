import React, { createContext, useReducer } from "react";

export const transparentLayerContext = createContext({
  isOpen: false,
  close: () => {},
  open: () => {},
  clickHandler: () => {},
});

const defaultState = {
  isOpen: false,
  close: () => {},
  open: () => {},
  clickHandler: () => {},
};

const reducer = (state, action) => {
  if (action.type === "OPEN") {
    return {
      ...state,
      isOpen: true,
      clickHandler: action.fn,
    };
  }

  if (action.type === "CLOSE") {
    return {
      ...state,
      isOpen: false,
      clickHandler: () => {},
    };
  }

  return defaultState;
};

const TransparentLayerProvider = (props) => {
  const [state, dispatchAction] = useReducer(reducer, defaultState);

  const close = () => {
    dispatchAction({ type: "CLOSE" });
    return;
  };

  const open = (fn) => {
    dispatchAction({ type: "OPEN", fn });
    return;
  };

  const ctx = {
    isOpen: state.isOpen,
    close,
    open,
  };

  return (
    <transparentLayerContext.Provider value={ctx}>
      {state.isOpen && (
        <div
          onClick={state.clickHandler}
          style={{
            background: "transparent",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
          }}
        ></div>
      )}
      {props.children}
    </transparentLayerContext.Provider>
  );
};

export default TransparentLayerProvider;
