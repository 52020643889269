import React, { useEffect } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";

const HowToList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="mask p-md-5 my-auto mxAuto ">
            <div className="col-12  mxAuto mb-5 px-auto">
              <h1 className="font58 White pt-5">How to List Your Business</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid col-11 mxAuto mt-5 maxWidth">
        <ul>
          <li className="font17 text-justify">
            Firstly, Sign up as a professional{" "}
          </li>
          <li className="font17 text-justify">
            Afterwards, you must provide your business information, including
            Business Name, City, Location, and Address.
          </li>
          <li className="font17 text-justify">Set your own 24/7 schedule </li>
          <li className="font17 text-justify">
            Next, you need to add a service menu to your business listing.
          </li>
          <li className="font17 text-justify">
            Add the name and price of the service.{" "}
          </li>
          <li className="font17 text-justify">
            (Optional) add relevant images as well.
          </li>
          <li className="font17 text-justify">
            Once you submit your request, you will be in live mode on Black
            Booking once the moderation team confirms it.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HowToList;
