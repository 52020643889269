import { message } from "antd";
import axios from "axios";

const Featured = (
  id,
  card_number,
  exp_month,
  exp_year,
  cvc,
  total_bill,
  subscription_days,
  Date_of_subscription
) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/featured_saloon_subscription/` +
    id;
  axios
    .put(URL, {
      card_number: card_number,
      exp_month: exp_month,
      exp_year: exp_year,
      cvc: cvc,
      total_bill: total_bill,
      subscription_days: subscription_days,
      Date_of_subscription: Date_of_subscription,
    })
    .then((res) => {
      console.log(res);
      if (res.data.success) {
        message.success(res.data.message);
        window.location = "/";
      } else {
        message.error(res.data.message);
      }
    });
};

export default Featured;
