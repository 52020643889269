import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import headerimage from "../../assests/headerimage.jpg";
import { Spin } from "antd";
import axios from "axios";

const LiveNotifications = () => {
  let id = localStorage.getItem("userId");
  const initialstate = {
    Notifications: [],
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { Notifications, update } = FormData;
  const getNotifications = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/user/notifications/` + id;

    axios.get(link).then((response) => {
      if (response.data.success) {
        setFormData({
          ...FormData,
          Notifications: response.data.data,
          update: true,
        });
      } else {
        setFormData({
          ...FormData,
          update: true,
        });
      }
    });
  };
  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, []);

  const RenderNotification = Notifications.slice(0)
    .reverse()
    .map((notification) => {
      return (
        <>
          <div className="p-3">
            <p className="notifications Bold mr-auto Black mb-0">
              {notification.notification}
            </p>
          </div>
          <hr className="mb-0 mt-0"></hr>
        </>
      );
    });

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />

        <div
          className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 mapImg"
          style={{ backgroundImage: `url(${headerimage})` }}
        >
          <div className="container-fluid">
            <div className="mask p-md-5 my-auto mxAuto ">
              <div className="col-12  mxAuto mb-5 px-auto">
                <h1 className="font58 White pt-5">Notifications</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="maxWidth mxAuto">
          <div className="col-11 mxAuto Orange mt-5 Radius_10">
            <p className="White font25 p-2">Alerts</p>
          </div>
          {Notifications.length !== 0 ? (
            <div className="bg-white col-11 col-md-8 col-lg-6 mxAuto mt-5 shadow-sm bordercolor Radius_16 mb-5 scroll">
              {RenderNotification}
            </div>
          ) : (
            <h3 className="text-center">No Notifications Yet</h3>
          )}
        </div>
      </>
    );
  }
};

export default LiveNotifications;
