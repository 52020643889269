import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import axios from "axios";
import { Spin } from "antd";
import PreviousTable from "../SubComponents/PreviousTable";

const UserBookings = () => {
  let id = localStorage.getItem("userId");
  const initialstate = {
    AllBookings: [],
    report: "",
    review: "",
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { update, AllBookings } = FormData;
  const getAllBookings = () => {
    const Allbooking =
      `${process.env.REACT_APP_BASE_URL}/api/user/all_bookings/` + id;
    axios.get(Allbooking).then((res) => {
      const sortData = res?.data?.data?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFormData({
        ...FormData,
        AllBookings: sortData,
        update: true,
      });
    });
  };
  useEffect(() => {
    getAllBookings();
    // eslint-disable-next-line
  }, []);
  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <h3 className="text-center font29 pt-5">Bookings</h3>
        <div className="col-10 mxAuto pb-5">
          <h4 className="pt-5">Bookings</h4>
          <PreviousTable data={AllBookings} getAllBookings={getAllBookings} />
        </div>
      </>
    );
  }
};

export default UserBookings;
