import React from "react";
import dateFormat from "dateformat";
import { Table } from "antd";

export default function ReviewComponent(props) {
  const data = props.data;
  function onChange(pagination, filters, sorter, extra) {}

  const columns = [
    {
      title: "Customer Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Review Date",
      render: (render) => dateFormat(render.date_of_review, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.date_of_review - b.date_of_review,
      },
    },
    {
      title: "Review",
      render: (render) => render.review,
    },
    {
      title: "Stars",
      render: (render) => render.stars,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        className="table-responsive"
      />
    </>
  );
}
