import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const Address = (country, street_address, apt, city, State, zip_code) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/personal_address/` +
    localStorage.getItem("userId");
  axios
    .put(URL, {
      country: country,
      street_address: street_address,
      apt: apt,
      city: city,
      State: State,
      zip_code: zip_code,
    })
    .then((res) => {
      if (res.data.success) {
        message.success(res.data.message);
        $("#address").addClass("d-none");
        $("#editAddresss").removeClass("d-none");
        $("#street_address").prop("disabled", true);
        $("#apt").prop("disabled", true);
        $("#zip_code").prop("disabled", true);
        $("#State").prop("disabled", true);
        $("#city").prop("disabled", true);
        $("#country").prop("disabled", true);
      } else {
        message.error(res.data.message);
      }
    });
};

export default Address;
