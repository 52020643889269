import React, { useEffect, useState, useCallback, useMemo } from "react";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { addTime, timeAvail } from "./ListYourBussiness/TimeSlotsAvail";
import { OPTIONS } from "./TimeSlot";
import TimeSlotsAvail from "./TimeSlotsAvail";
import moment from "moment-timezone";
import "./Calendar.css";
import { Button, Modal, Select } from "antd";

const CalendarCus = React.memo((props) => {
  const today = moment(); // Current date with moment
  const [show, setShow] = useState(false);
  const [dateConfig, setDateConfig] = useState({
    month: today.month(),
    year: today.year(),
  });
  const [selectedDate, setSelectedDate] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
console.log(props.preDefined,"props.preDefined")
  if (props.preDefined) addTime(props.preDefined);

  useEffect(() => {
    setDateConfig({ month: today.month(), year: today.year() });
  }, []);

  const daysInMonth = useMemo(() => moment().year(dateConfig.year).month(dateConfig.month).daysInMonth(), [dateConfig]);
  const firstDayOfMonth = useMemo(() => moment().year(dateConfig.year).month(dateConfig.month).date(1).day(), [dateConfig]);

  // Utility function to format date consistently
  const formatDate = (date) => moment(date).tz("UTC").format("YYYY-MM-DD");

  // Filter available options for time slots
  const filteredOptions = useMemo(
    () => OPTIONS.filter((o) => !selectedItems.includes(o)),
    [selectedItems]
  );

  const handlePrevMonth = useCallback(() => {
    setDateConfig((prev) => ({
      ...prev,
      month: prev.month === 0 ? 11 : prev.month - 1,
      year: prev.month === 0 ? prev.year - 1 : prev.year,
    }));
  }, []);

  const handleNextMonth = useCallback(() => {
    setDateConfig((prev) => ({
      ...prev,
      month: prev.month === 11 ? 0 : prev.month + 1,
      year: prev.month === 11 ? prev.year + 1 : prev.year,
    }));
  }, []);

  const changeDate = useCallback((dayNumber) => {
    const date = formatDate(moment().year(dateConfig.year).month(dateConfig.month).date(dayNumber));
    const foundTime = timeAvail.find((d) => d.date === date);
    setSelectedItems(foundTime ? foundTime.time : []);
    setSelectedDate(date);
  }, [dateConfig]);

  const toggleTimeSlot = useCallback(() => {
    const index = timeAvail.findIndex((b) => {
      console.log(b.date,selectedDate)

      
      return b.date === selectedDate});
    console.log(index,Date())
    if (index !== -1) {
      timeAvail[index].closed = !timeAvail[index].closed;
      timeAvail[index].time = [];
      setSelectedItems([]);
    } else {
      timeAvail.push({
        time: [],
        date: selectedDate,
        closed: false,
      });
      setSelectedItems([]);
    }
  }, [selectedDate]);

  const timeHandler = useCallback((time) => {
    const index = timeAvail.findIndex((b) => b.date === selectedDate);
    if (index !== -1) {
      timeAvail[index].time = time;
    } else {
      timeAvail.push({
        time,
        date: selectedDate,
        closed: false,
      });
    }
    setSelectedItems(time);
  }, [selectedDate]);

  return (
    <>
      {show ? (
        <TimeSlotsAvail changeShow={() => setShow(false)} date={selectedDate} />
      ) : (
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center my-2">
            <button onClick={handlePrevMonth} className="button-calender">
              <CaretLeftOutlined style={{ fontSize: "20px" }} />
            </button>
            <div className="text-center">
              {moment().year(dateConfig.year).month(dateConfig.month).format("MMMM YYYY")}
            </div>
            <button onClick={handleNextMonth} className="button-calender">
              <CaretRightOutlined style={{ fontSize: "20px" }} />
            </button>
          </div>
          <table className="w-100 text-center">
            <thead>
              <tr>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: Math.ceil((firstDayOfMonth + daysInMonth) / 7) }).map((_, weekIndex) => (
                <tr key={weekIndex}>
                  {Array.from({ length: 7 }).map((_, dayIndex) => {
                    const dayNumber = weekIndex * 7 + dayIndex + 1 - firstDayOfMonth;
                    const isDisabled = dayNumber <= 0 || dayNumber > daysInMonth;

                    return (
                      <td key={dayIndex}>
                        {!isDisabled && (
                          <div className="position-relative">
                            <div
                              onMouseEnter={() => changeDate(dayNumber)}
                              onClick={() => {
                                changeDate(dayNumber);
                                setModalVisible(true);
                              }}
                              className={
                                today.isSameOrBefore(moment().year(dateConfig.year).month(dateConfig.month).date(dayNumber), "day")
                                  ? "calender-date"
                                  : "calender-date-not-hover"
                              }
                            >
                              <div className="cursor">{dayNumber}</div>
                            </div>
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        open={modalVisible}
        title={`Select Time Slots for ${moment(selectedDate).format("MMMM DD, YYYY")}`}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>
            Close
          </Button>,
          <Button key="add" type="primary" onClick={() => setModalVisible(false)}>
            Add
          </Button>,
        ]}
      >
        <div className="text-left">
          <Button
            className="btn mx-3"
            style={{ background: "#f0ac56", color: "white", fontWeight: 700 }}
            onClick={toggleTimeSlot}
          > 
          {timeAvail.some((tim) => {
            console.log(tim, tim.closed);
            return tim.date === selectedDate && !tim.closed;
          })
            ? "Close Time Slots"
            : "Open Time Slots"}
          </Button>
          {timeAvail.some((tim) => tim.date === selectedDate && (!tim.closed || tim.closed === undefined)) && (
            <div className="flex-grow-1 mx-3">
              <div className="my-1">Click all slots below:</div>
              <Select
                mode="multiple"
                placeholder="Select Time Slots"
                value={selectedItems}
                onChange={timeHandler}
                style={{ width: "100%" }}
                options={filteredOptions.map((item) => ({ value: item, label: item }))}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});

export default CalendarCus;
