import React, { useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import featuredBg from "../../assests/featuredBg.jpg";
import { message } from "antd";
import Featured from "../../API/FeaturedSaloon/Featured";
const FeatureSaloon = () => {
  var Date_of_subscription = new Date().toISOString();

  let id = localStorage.getItem("venderId");
  const initialstate = {
    // currentDate: date,
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    total_bill: 10,
    subscription_days: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  const {
    exp_month,
    card_number,
    exp_year,
    cvc,
    total_bill,
    subscription_days,
  } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  console.log(Date_of_subscription);

  const validation = () => {
    if (card_number !== "") {
      if (exp_year !== "") {
        if (exp_month !== "") {
          if (cvc !== "") {
            if (subscription_days !== "") {
              Featured(
                id,
                card_number,
                exp_month,
                exp_year,
                cvc,
                total_bill,
                subscription_days,
                Date_of_subscription
              );
            } else if (subscription_days === "") {
              setFormData({
                ...FormData,
                error: "Subscription days are Required",
              });
              message.error("Subscription days are Required");
            }
          } else if (cvc === "") {
            setFormData({
              ...FormData,
              error: "Card's CVC is Required",
            });
            message.error("Card's CVC is Required");
          }
        } else if (exp_month === "") {
          setFormData({
            ...FormData,
            error: "Enter Card's Month of expiry",
          });
          message.error("Enter Card's Month of expiry");
        }
      } else if (exp_year === "") {
        setFormData({
          ...FormData,
          error: "Expiry Year is Required",
        });
        message.error("Expiry Year is Required");
      }
    } else if (card_number === "") {
      setFormData({
        ...FormData,
        error: "Card Number is Required",
      });
      message.error("Card Number is Required");
    }
  };
  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1  bg-image img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${featuredBg})` }}
      >
        <div className="maxWidth ">
          <div className="form-group col-11 mxAuto padding30">
            <div className="col-lg-6 col-md-12 col-12 pb-3 px-0 mx-auto bg-white shadow mt-5">
              <div className="bgGrey">
                <h3 className="col-10 mx-auto font24 m-0">Payment</h3>
              </div>

              <div className="form-group paddingTop_3 col-11 mx-auto mb-0">
                <div className="col-12 pt-2">
                  <input
                    type="text"
                    className="form-control rounded-0 fillcolor"
                    placeholder="Card Number"
                    name="card_number"
                    value={card_number}
                    onChange={onHandleChange}
                  ></input>
                </div>
                <div className="pt-2">
                  <div className="row ">
                    <div className=" col-md-4 col-12">
                      <input
                        type="text"
                        className="form-control rounded-0 fillcolor "
                        placeholder="Expiry year"
                        name="exp_year"
                        value={exp_year}
                        onChange={onHandleChange}
                      ></input>
                    </div>
                    <div className="col-md-4 col-12">
                      <select
                        className="form-control rounded-0 fillcolor height44 "
                        name="exp_month"
                        value={exp_month}
                        onChange={onHandleChange}
                      >
                        <option selected>Month</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>

                    <div className=" col-md-4 col-12">
                      <input
                        type="text"
                        className="form-control rounded-0 fillcolor  "
                        placeholder="CVC"
                        name="cvc"
                        value={cvc}
                        onChange={onHandleChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-2">
                  <select
                    className="form-control rounded-0 fillcolor height44"
                    name="subscription_days"
                    value={subscription_days}
                    onChange={onHandleChange}
                  >
                    <option selected>Select Days</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <p className="p-3 mb-0 ">
                  <strong>Total Bill:</strong>${total_bill}
                </p>
              </div>
              <div className="col-11 mx-auto pb-5">
                <button
                  type="button"
                  className="btn d-block mx-auto col-11 Orange White rounded-0"
                  onClick={validation}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSaloon;
