import React from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import headerimage from "../../assests/headerimage.jpg";
import { NavLink } from "react-router-dom";

const Login = () => {
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="mask p-md-5 my-auto mxAuto ">
            <div className="col-12  mxAuto mb-5 px-auto">
              <h1 className="font58 White pt-5">Login</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 col-11 mxAuto">
        <div className="row">
          <div className="card cutomhover col-md-5 m-1 mxAuto col-12 shadow cursor ">
            <div className="card-body ">
              <h5 className="card-title ">Are you a User?</h5>
              <NavLink to="/customerlogin" className="Black cursor">
                <h3 className="cursor">Click Here to Login</h3>
              </NavLink>
            </div>
          </div>
          <div className="card cutomhover col-md-5 m-1 mxAuto col-12 shadow cursor">
            <div className="card-body">
              <h5 className="card-title ">Are you a Vendor?</h5>
              <NavLink to="/vendorlogin" className="Black pt-5 ">
                <h3 className="cursor">Click Here to Login</h3>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
