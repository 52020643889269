import React from "react";
import moment from "moment";
import { timeAvail } from "./ListYourBussiness/TimeSlotsAvail";
import { OPTIONS } from "./TimeSlot";

const TimeSlotsAvail = (props) => {
  const changeHandler = (d) => {
    const index = timeAvail.findIndex((b) => b.date === props.date);
    if (index !== -1) {
      const checkTimeSlot = timeAvail[index].time.findIndex((tim) => tim === d);
      if (checkTimeSlot !== -1) {
        timeAvail[index].time.splice(checkTimeSlot, 1);
      } else {
        timeAvail[index].time.push(d);
      }
    } else {
      timeAvail.push({
        time: [d],
        date: props.date,
      });
    }
  };

  return (
    <div>
      <div className="text-center my-4">
        {moment(props.date).format("DD-MMMM-YYYY")}
      </div>
      <table width={"100%"} style={{ height: "200px", overflowY: "scroll" }}>
        <tr>
          <th>Time</th>
          <th>Availability</th>
          <th>Book Now</th>
        </tr>
        {OPTIONS.map((d) => (
          <tr>
            <td>{d.time}</td>
            {/* {/* <td>{d.endTime}</td> */}
            <td> Available</td>
            <td>
              {/* {d.price} $ */}
              <input
                type="checkbox"
                value={true}
                checked={
                  timeAvail.find(
                    (tim) =>
                      tim.date === props.date &&
                      tim.time.find((time) => time === d.time)
                  )
                    ? true
                    : false
                }
                className="cursor-pointer ms-2"
                onChange={() => changeHandler(d.time)}
              />
            </td>
          </tr>
        ))}
      </table>
      <div
        className="text-center my-3 cursor"
        onClick={() => props.changeShow()}
      >
        Ok
      </div>
    </div>
  );
};

export default TimeSlotsAvail;
