import React from "react";
import { ReactComponent as DeleteIcon } from "../../../assests/delete.svg";

const RenderMapComp = ({
  servicemap,
  onUpdateName,
  onUpdatePrice,
  onUpdatetime,
  onUpdatedeposit,
  removeService,
  onUpdateDescription,
}) => {
  return (
    <div className="col-12">
      <div className="row">
        <input
          type="text"
          className=" fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
          name="service_name"
          id={servicemap.serviceid + "service_name"}
          value={servicemap.service_name}
          onChange={onUpdateName}
          placeholder="Service Name"
        />
        <input
          type="text"
          className=" fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
          name="service_price"
          id={servicemap.serviceid + "service_price"}
          value={servicemap.service_price}
          onChange={onUpdatePrice}
          placeholder="Service Price"
        />
        <input
          type="number"
          className=" fillcolor p-2 col-sm-2 col-6 px-0 py-0  mb-0 bordercolor rounded-0"
          name="service_time"
          id={servicemap.serviceid + "service_time"}
          value={servicemap.service_time}
          onChange={onUpdatetime}
          placeholder="dur(min)"
          min={1}
        />
        <input
          type="number"
          className=" fillcolor p-2 col-sm-2 col-6 px-0 py-0  mb-0 bordercolor rounded-0"
          name="service_deposit"
          id={servicemap.serviceid + "service_deposit"}
          value={servicemap.service_deposit}
          onChange={onUpdatedeposit}
          placeholder="deposit"
          min={0}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "0px",
            margin: "10px 0px",
          }}
        >
          <input
            type="text"
            className=" fillcolor p-2 col-sm-4 col-12 px-0 py-0  mb-0 bordercolor rounded-0"
            name="service_description"
            id={servicemap.serviceid + "service_description"}
            value={servicemap.service_description}
            onChange={onUpdateDescription}
            placeholder="Service Description"
          />
          <button
            type="button"
            className="btn  mb-5 mb-sm-0  mx-auto"
            onClick={() => removeService(servicemap.serviceid)}
          >
            {" "}
            <DeleteIcon style={{ height: "24px", width: "24px" }} />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenderMapComp;
