import React from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
const Success = () => {
  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <div className="col-11 mxAuto">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt;Payouts
            </p>
            <h1 className="White  pb-3">Payouts</h1>
          </div>
        </div>
      </div>

      <div className="maxWidth">
        <div className="col-11 row mxAuto pt-3 px-4">
          <div className="col-12 col-md-5 ">
            <h1 className="font22">
              Congratulations! Your account has successfully connected with
              Stripe
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
