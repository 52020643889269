import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const DateofBirth = (date_of_birth) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/date_of_birth/` +
    localStorage.getItem("userId");
  axios
    .put(URL, {
      date_of_birth: date_of_birth,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Date of Birth Updated Successfully");
        $("#dob").addClass("d-none");
        $("#editDob").removeClass("d-none");
        $("#date_of_birth").prop("disabled", true);
      } else {
        message.error(res.data.message);
      }
    });
};

export default DateofBirth;
