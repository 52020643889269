import { message } from "antd";
import axios from "axios";
const UserInvite = (email, user_id) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/comunity_invitation/` + user_id;
  axios
    .post(URL, {
      user_id: user_id,
      email: email,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Invitation sent");
        // window.location = "/";
      } else {
        message.error(res.data.message);
      }
    });
};

export default UserInvite;
