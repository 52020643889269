import React, { useEffect } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 mxAuto px-0">
              <div className="mask p-md-5 my-auto mxAuto ">
                <div className="col-12  mxAuto mb-5 px-auto">
                  <h1 className="White pt-5">Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-10 mxAuto mt-5 content maxWidth">
          <p className="font18 text-justify">
            The Privacy Policy of Black Booking is open to all stakeholders. The
            use of this platform indicates your agreement with this Privacy
            Policy.
          </p>
          <h3>INFORMATION COLLECTED</h3>
          <p className="font18 text-justify">
            As for black booking professionals , Your basic information is
            required apon registry . Ex: business name , business address ,
            phone number , email address.
          </p>
          <p className="font18 text-justify">
            Depending on the services you choose, the website may also require
            that you provide billing information, such as billing name and
            address, credit card number.
          </p>

          <h3>Information Processing</h3>
          <p className="font18 text-justify">
            The company will process the information for:
          </p>
          <p className="font18 text-justify">
            Contacting the relevant stakeholder
          </p>
          <p className="font18 text-justify">
            Transferring the booking information to the relevant parties
          </p>
          <p className="font18 text-justify">Data storage for future use</p>
          <p className="font18 text-justify">
            Analyses of Web Navigational behavior for performance optimization
          </p>
          <p className="font18 text-justify">
            All of the data are collected with explicit consent from all
            parties. It can be deleted and removed upon request. Contact us if
            you have any concerns about information collection and processing.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
