import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assests/plus.svg";
import { Switch, Input, Modal, Spin } from "antd";
import {
  InstagramFilled,
  FacebookFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import MapEdit from "../../MapEdit";
import CalenderCus from "../CalenderCus";

const EditBussinessForm = (props) => {
  const [images, setimages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  // const findDate = props.working_hours.filter((d) => d.date !== null);
  // const currentDate = new Date(findDate[0].date);
  // const daysInMonth = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() + 1,
  //   0
  // ).getDate();
  // const datesArray = Array.from(
  //   { length: daysInMonth },
  //   (_, index) =>
  //     new Date(currentDate.getFullYear(), currentDate.getMonth(), index + 1)
  // );
  // const filteredOptions = OPTIONS.filter((o) => !props.monTime?.includes(o));

  // const renderDates = datesArray.map((date, index) => (
  //   <div key={index} className="row">
  //     <div className="col-12 col-sm-3 mt-2">
  //       <p className="mb-0">
  //         {date.toLocaleDateString("en-US", {
  //           year: "numeric",
  //           month: "short",
  //           day: "numeric",
  //         })}
  //       </p>
  //     </div>
  //     <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
  //       <div className="form-check mr-auto mx-sm-auto">
  //         <input
  //           className="form-check-input"
  //           type="checkbox"
  //           name="closed"
  //           checked={props.working_hours[index].closed}
  //           onChange={(e) => props.working_hours_close(index, e)}
  //           id="MonClose"
  //         />
  //         <label className="form-check-label" htmlFor="MonClose">
  //           Closed
  //         </label>
  //       </div>
  //     </div>
  //     <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
  //       <div
  //         className={` ${
  //           props.working_hours[index].closed === true ? "d-none" : "show"
  //         }`}
  //       >
  //         <Select
  //           mode="multiple"
  //           placeholder="Time Slots"
  //           value={props.working_hours[index].time}
  //           onChange={(selectedItems) =>
  //             props.working_hours_time(index, selectedItems, date)
  //           }
  //           style={{ width: "100%", background: "grey" }}
  //         >
  //           {filteredOptions.map((item) => (
  //             <Select.Option
  //               key={item}
  //               value={item}
  //               className="col-12 text-center"
  //             >
  //               {item}
  //             </Select.Option>
  //           ))}
  //         </Select>
  //       </div>
  //     </div>
  //   </div>
  // ));

  useEffect(() => {
    setimages(props.images);
  }, [props.images]);

  const deleteImage = (id, imageId) => {
    const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/delete_images/${id}`;
    axios.put(URL, { images: [imageId] }).then((res) => {
      setimages(res.data.data.images);
    });
  };

  const uploadImage = (e) => {
    props.onHandleImageChange(e.target.files[0]);
    const previewUrl = URL.createObjectURL(e.target.files[0]);
    setNewImages([...newImages, previewUrl]);
  };

  const deleteNewImage = (index) => {
    const newImagesCopy = [...newImages];
    newImagesCopy.splice(index, 1);
    setNewImages(newImagesCopy);
  };
  const [paymentSelect, setPaymentSelect] = useState(props.payment_type);
  console.log(paymentSelect);
  const changehandler = (e) => {
    props.onHandleChange(e);
    setPaymentSelect(e.target.value);
  };
  return (
    <div className="maxWidth">
      <div className="row col-md-11 col-12 mxAuto pt-5 ">
        <div className="col-md-10 col-12 col-lg-7 mxAuto padding_5 bg-white earn shadow rounded-0">
          <h1 className=" font39 bdrBotom py-2">
            Earn Money by Getting Bookings from{" "}
            <span style={{ color: "#ea9223" }}>Blackbooking</span>
          </h1>
          <h3 className="text-center mt-0 mt-md-4 mb-0">Edit Your Business</h3>

          <h6 className="mt-2 Bold">Payment Type</h6>
          <div className="col-12 px-0">
            <ul className="col-12 col-md-12 mx-auto combineButton form RadioToButton d-inline-flex px-0 mb-4">
              <li
                className={`w-50  ${
                  paymentSelect === "full" || props.payment_type === "full"
                    ? "checkeddd"
                    : null
                }`}
              >
                <input
                  type="radio"
                  id="full"
                  name="payment_type"
                  value="full"
                  onClick={changehandler}
                />
                <label for="full" className="py-2 mb-0 Bold cursor">
                  Full
                </label>
              </li>
              <li
                className={`w-50 ${
                  paymentSelect === "deposit" ||
                  props.payment_type === "deposit"
                    ? "checkeddd"
                    : null
                }`}
              >
                <input
                  type="radio"
                  id="deposit"
                  name="payment_type"
                  value="deposit"
                  onClick={changehandler}
                />
                <label for="deposit" className="py-2 mb-0 Bold cursor">
                  Deposit
                </label>
              </li>
              <li
                className={`w-50 ${
                  paymentSelect === "both" || props.payment_type === "both"
                    ? "checkeddd"
                    : null
                }`}
              >
                <input
                  type="radio"
                  id="both"
                  name="payment_type"
                  value="both"
                  onClick={changehandler}
                />
                <label for="both" className="py-2 mb-0 Bold cursor">
                  Both
                </label>
              </li>
            </ul>
          </div>
          <h6 className="mt-2 Bold">Enter your paypal account</h6>
          <div className="row">
            <div className="col-12 px-0">
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Paypal Email"
                className="rounded-0 fillcolor p-2 col-12  mb-0 bordercolor"
                name="paypalEmail"
                placeholder="Enter your Paypal Email"
                value={props.paypalEmail}
                onChange={props.onHandleChange}
              />
            </div>
          </div>

          <h6 className="mt-2 mb-2 Bold">Business Information</h6>

          <form>
            <div className="mb-3 d-flex gap-1">
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Business Name Required"
                className="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor"
                name="business_name"
                placeholder="Business Name"
                value={props.business_name}
                onChange={props.onHandleChange}
              />
              <input
                type="text"
                data-toggle="tooltip"
                data-placement="left"
                title="Business Website Required"
                className="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor"
                name="website"
                placeholder="Business Website"
                value={props.website}
                onChange={props.onHandleChange}
              />
            </div>
            <div
              style={{
                marginTop: "4rem",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <div className="d-flex gap-1 display">
                <input
                  type="text"
                  readOnly
                  name="city"
                  placeholder="City from Map"
                  className="rounded-0 fillcolor p-2 col-sm-6 col-12  mb-0 bordercolor"
                  value={props.city}
                />
                <input
                  type="text"
                  readOnly
                  name="state"
                  placeholder="State from Map"
                  className="rounded-0 fillcolor p-2 col-sm-6 col-12 mb-0 bordercolor"
                  // onChange={() => props.onHandleChange()}
                  value={props.State}
                />
              </div>
              <div style={{ marginTop: "0rem" }}>
                <input
                  type="text"
                  name="address_line_1"
                  className="rounded-0 fillcolor p-2 col-12  mb-0 bordercolor"
                  onChange={props.onHandleChange}
                  value={props.address_line_1}
                  placeholder="Address"
                />
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <div className="mapHeight">
                <MapEdit
                  onChange={props.onPlaceChange}
                  address={props.address_line_1}
                  google={props.google}
                  center={{
                    lat: 40.711391223589956,
                    lng: -74.00283887121081,
                  }}
                  funt={props.onMarkerDragEnd}
                  city={props.city}
                  State={props.State}
                  addressChange={props.onHandleChange}
                  getAddress={props.addressChange}
                  height="300px"
                  zoom={14}
                />
              </div>
            </div>
          </form>

          <h6 className="mt-5 mb-2 Bold pt-3">
            Please Select All Opening Time For Each Day
            <div>
              <CalenderCus preDefined={props.working_hours} />
            </div>
          </h6>

          <p>No bookings will overlap.</p>
          <div>
            {/* {renderDates} */}

            {/* <div className="row">
              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Monday</h6>
              </div>
              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="closed"
                    checked={props.monTimeClose === true && true}
                    onChange={(e) => props.monTimeUpdate("checkbox", e)}
                    id="MonClose"
                  />
                  <label className="form-check-label" for="MonClose">
                    Closed{" "}
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.monTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.monTime}
                    handleTimeChange={props.monTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Tuesday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="closed"
                    id="tueClose"
                    checked={props.tueTimeClose === true && true}
                    onChange={(e) => props.tueTimeUpdate("checkbox", e)}
                  />
                  <label className="form-check-label" for="tueClose">
                    Closed
                  </label>
                </div>
              </div>
              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.tueTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.tueTime}
                    handleTimeChange={props.tueTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Wednesday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="closed"
                    checked={props.wedTimeClose === true && true}
                    id="wedClose"
                    onChange={(e) => props.wedTimeUpdate("checkbox", e)}
                  />
                  <label className="form-check-label" for="wedClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.wedTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.wedTime}
                    handleTimeChange={props.wedTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Thursday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="closed"
                    checked={props.thurTimeClose === true && true}
                    onChange={(e) => props.thurTimeUpdate("checkbox", e)}
                    id="thurClose"
                  />
                  <label className="form-check-label" for="thurClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.thurTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.thurTime}
                    handleTimeChange={props.thurTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Friday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    checked={props.friTimeClose === true && true}
                    onChange={(e) => props.friTimeUpdate("checkbox", e)}
                    id="FriClose"
                  />
                  <label className="form-check-label" for="FriClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.friTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.friTime}
                    handleTimeChange={props.friTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Saturday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    checked={props.satTimeClose === true && true}
                    onChange={(e) => props.satTimeUpdate("checkbox", e)}
                    id="satClose"
                  />
                  <label className="form-check-label" for="satClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.satTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.satTime}
                    handleTimeChange={props.satTimeUpdate}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-3 mt-2">
                <h6 className="mb-0">Sunday</h6>
              </div>

              <div className="col-12 col-sm-3 pl-4 pl-sm-0 my-2 d-inline-flex min32">
                <div className="form-check mr-auto mx-sm-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="closed"
                    checked={props.sunTimeClose === true && true}
                    onChange={(e) => props.sunTimeUpdate("checkbox", e)}
                    id="sunClose"
                  />
                  <label className="form-check-label" for="sunClose">
                    Closed
                  </label>
                </div>
              </div>

              <div className="col-10 col-sm-6 my-1 ml-5 ml-sm-0 px-0">
                <div
                  className={` ${
                    props.sunTimeClose === true ? "d-none" : "show"
                  }`}
                >
                  <TimeSlot
                    selected={props.sunTime}
                    handleTimeChange={props.sunTimeUpdate}
                  />
                </div>
              </div>
            </div> */}
          </div>

          <h6 className="mt-4 mb-2 Bold">Add Service Menu</h6>

          <form>
            <div className="row">
              <div className="col-12 mx-0 px-0 mb-1">{props.RenderMap}</div>
              <div className="col-12">
                <div className="row">
                  <input
                    type="text"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Service Name Required"
                    className="fillcolor p-2 col-sm-4 col-12  mb-0 bordercolor rounded-0"
                    name="service_name"
                    placeholder="Service Name"
                    value={props.service_name}
                    onChange={props.onHandleChange}
                  />
                  <input
                    type="text"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Service Price Required "
                    className="fillcolor p-2 col-sm-4 col-12  mb-0 bordercolor rounded-0"
                    name="service_price"
                    placeholder="Service Price in $!"
                    value={props.service_price}
                    onChange={props.onHandleChange}
                  />
                  <input
                    type="number"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Service time Required"
                    className="fillcolor p-2 col-sm-2 col-6  mb-0 bordercolor rounded-0"
                    placeholder="dur(min)"
                    value={props.service_time}
                    name="service_time"
                    onChange={props.onHandleChange}
                    min={1}
                  />
                  <input
                    type="number"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Deposit Amount"
                    className="fillcolor p-2 col-sm-2 col-6  mb-0 bordercolor rounded-0"
                    placeholder="deposit"
                    value={props.service_deposit}
                    name="service_deposit"
                    onChange={props.onHandleChange}
                    min={0}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // padding: "0px",
                paddingLeft: "10px",
                margin: "10px 0px",
              }}
            >
              <input
                type="text"
                data-placement="left"
                title="Service Description"
                className="fillcolor col-sm-4 col-12  py-0  mb-0 bordercolor rounded-0"
                style={{
                  paddingLeft: "10px !important",
                }}
                name="service_description"
                id="service_description"
                placeholder="Service Description"
                value={props.service_description}
                onChange={props.onHandleChange}
              />
              <button
                type="button"
                className="btn d-block mxAuto White "
                onClick={props.Service}
              >
                <PlusIcon />{" "}
              </button>
            </div>
          </form>
          <div>
            {/* <UploadComponent2 limit={7} imagesSet={props.imagesSet} /> */}
          </div>

          <div>
            <h6 className="mt-4 mb-2 Bold">Upload Images</h6>
            <p>only png, jpeg, jpg images are accepted</p>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              {images &&
                images.map((image, i) => {
                  return (
                    <div
                      style={{ position: "relative", width: "fit-content" }}
                      key={i}
                    >
                      <img
                        src={image}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #EA9222",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          fontSize: "1.5rem",
                          top: "-12px",
                          right: "0",
                          cursor: "pointer",
                          color: "#EA9222",
                        }}
                        onClick={() => deleteImage(props.id, image)}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </div>
                  );
                })}
              {newImages &&
                newImages.map((image, i) => {
                  return (
                    <div
                      style={{ position: "relative", width: "fit-content" }}
                      key={i}
                    >
                      <img
                        src={image}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #EA9222",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          fontSize: "1.5rem",
                          top: "-12px",
                          right: "0",
                          cursor: "pointer",
                          color: "#EA9222",
                        }}
                        onClick={() => deleteNewImage(i)}
                      >
                        <CloseCircleOutlined />
                      </div>
                    </div>
                  );
                })}
              <label
                style={{
                  height: "100px",
                  width: "100px",
                  backgroundColor: "#FAFAFA",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "1px solid #e5e5e5",
                }}
              >
                <PlusIcon />
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={uploadImage}
                />
              </label>
            </div>
          </div>
          <h6 className="mt-4 mb-2 Bold">Amenities</h6>
          <div className="form-group row">
            <label for="Book" className="font16 col-8">
              Instant Booking
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="Book"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.book}
                onChange={props.onBookChange}
                className="ml-auto"
              />
            </div>
          </div>

          <div className="form-group row">
            <label for="WiFI" className="font16 col-8">
              Wi-Fi
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="WiFi"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.WiFi}
                onChange={props.onWiFiChange}
                className="ml-auto"
              />
            </div>
          </div>

          <div className="form-group row">
            <label for="Travel" className="font16 col-8">
              Mobile service
            </label>
            <div className="col-4 my-auto">
              <Switch
                id="Travel"
                unCheckedChildren="OFF"
                checkedChildren="ON"
                checked={props.trevel_to_me}
                onChange={props.onTravelChange}
                className="ml-auto"
              />
            </div>
          </div>
          <h6 className="mt-4 mb-2 Bold">Social Links</h6>
          <div className="form-group">
            <Input
              size="large"
              placeholder="Facebook Link"
              name="Facebook"
              onChange={props.onHandleChange}
              prefix={<FacebookFilled />}
            />
            <Input
              size="large"
              placeholder="Instagram Link"
              name="Instagram"
              onChange={props.onHandleChange}
              prefix={<InstagramFilled />}
            />
          </div>

          <button
            className="btn White Orange d-block col-md-5 col-12 p-2 rounded-0 mxAuto"
            type="button"
            disabled={props.openModel}
            onClick={props.validation}
          >
            Submit Request
          </button>
          <p
            className="text-danger text-center mb-0 py-2"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            {props.error}
          </p>
          <Modal
            className="OptPopUp"
            open={props.openModel}
            footer={null}
            closable={false}
          >
            <Spin spinning={props.loading}>
              <p className="text-center font-weight-bolder pt-4 Black">
                Please Wait, Sending Your Request
              </p>
            </Spin>
          </Modal>
        </div>

        <div className="col-10 col-lg-4 mxAuto paddingTop_3">
          <h4 className="font34">Why Black Booking?</h4>
          <p className="font17">
            Professionals have an excellent opportunity to increase their
            customer base. As a business owner, you will be able to make more
            money through direct appointments with clients using Black Booking.{" "}
          </p>
          <p className="font17">
            For your listing page, you will be provided with personalized
            booking forms and detailed services portfolios. By doing this, you
            will be able to let more people know about all of the services you
            tend to offer.
          </p>
          <p className="font17">
            Appointments scheduled in advance benefit all the stakeholders. What
            lies ahead for you in the days and weeks to come is known to you. If
            you present yourself as a worthy one and secure compelling reviews
            on Black Booking, you can land high ticket clients as well. You can
            give us all the information about your business and the service you
            offer, and we will find new customers for you.
          </p>
        </div>
      </div>

      <h1 className="text-center pt-5">4 Step Process</h1>
      <div className="col-11 mxAuto">
        <div className="row pt-3">
          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Edit Your Business</h3>
                <p className="card-text ">
                  Make sure all the necessary information about your business is
                  registered, such as its name, location, and other required
                  information.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0 ">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Add Service Details</h3>
                <p className="card-text ">
                  Provide us with all your service details and their pricing.
                  Evaluate minute details explicitly for better customer
                  understanding.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Get Bookings</h3>
                <p className="card-text ">
                  When you are set up on Black Booking, our booking portal will
                  allow you to receive booking appointments. Your best effort
                  should be put into entertaining them.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-11 mxAuto mt-2 shadow-sm rounded-0">
            <div className="card rounded-0   ">
              <div className="card-body p-4 ">
                <h3 className="pt-3  uAccount">Get Paid</h3>
                <p className="card-text ">
                  You will see the payment reflected in your account after the
                  booking has been processed and confirmed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBussinessForm;
