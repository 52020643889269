import { message } from "antd";
import axios from "axios";

const LoginGoogle = (first_name, last_name, email, password, gmail_login) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/user/signup`;
  axios
    .post(URL, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      gmail_login: gmail_login,
    })
    .then((res) => {
      if (res.data.success) {
        localStorage.setItem("type", "user");
        localStorage.setItem("userId", res.data.data._id);
        message.success("Welcome !");
        window.location = "/";
      } else {
        message.error(res.data.message);
      }
    });
};

export default LoginGoogle;
