import React from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
const Fail = () => {
  let id = localStorage.getItem("venderId");

  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <div className="col-11 mxAuto">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt;Payouts
            </p>
            <h1 className="White  pb-3">Payouts</h1>
          </div>
        </div>
      </div>

      <div className="maxWidth">
        <div className="col-11 row mxAuto pt-3 px-4">
          <div className="col-12 col-md-5 ">
            <h1 className="font22">
              Error! Please Click the button below to try again
            </h1>
            <NavLink to={"/paymentandpayout/" + id}>
              <button className="btn Orange White mt-2 col-12 rounded-0">
                Click to Try Again
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fail;
