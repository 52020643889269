import React from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import axios from "axios";
import { message } from "antd";
import ChatUser from "../../API/Chat/ChatUser";
import $ from "jquery";

class UserChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: localStorage.getItem("userId"),
      vendor_id: props.location.state.record.vendor.vendor_id,
      message1: "",
      allMessages: [],
    };
    this.onHandleChange = this.onHandleChange.bind(this);
  }

  getMessages = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/message/` +
      this.state.user_id +
      `/` +
      this.state.vendor_id;
    axios
      .get(link)
      .then((response) => {
        if (response.data.success === true) {
          if (response.data.message !== "No message yet") {
            this.setState({
              allMessages: response.data.data,
              message1: "",
            });
            $("#chatBox").scrollTop($("#chatBox")[0]?.scrollHeight);
          } else {
            this.setState({
              allMessages: [],
            });
          }
        } else {
          message.error(response.data.message);
        }
      })
      .catch(function (error) {
        message.error(error);
      });
  };

  componentDidMount() {
    this.getMessages();
  }

  onHandleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  render(
    // eslint-disable-next-line
    RenderChats = this.state.allMessages.map((item) => {
      if (item.user_message === true) {
        return (
          <>
            <div class="col-8 ml-auto" style={{ maxWidth: "75%" }}>
              <p
                className="Orange White ml-auto Radius_20 px-3 py-2"
                style={{ width: "fit-content", maxWidth: "100%" }}
              >
                {item.message}
              </p>
            </div>
          </>
        );
      } else if (item.vendor_message === true) {
        return (
          <>
            <div class="col-8" style={{ maxWidth: "75%" }}>
              <p
                className="backgroundGrey Black mr-auto Radius_20 px-3 py-2"
                style={{ width: "fit-content", maxWidth: "100%" }}
              >
                {item.message}
              </p>
            </div>
          </>
        );
      }
    })
  ) {
    return (
      <>
        <BlackNavbar />
        <div className="backgroundGrey pb-5">
          <p className="text-center font29 pt-3 pt-md-4">
            Chat with {this.props.location.state.record.vendor.business_name}
          </p>

          <div className="maxWidth mxAuto pb-5">
            <div className="col-11 mxAuto">
              <div
                className="col-lg-7 col-md-8 col-12 mx-auto scroll"
                id="chatBox"
              >
                <div className="row">
                  {this.state.allMessages.length !== 0 ? (
                    RenderChats
                  ) : (
                    <p>Start Chatting Now</p>
                  )}
                </div>
              </div>
              <div className="col-lg-7 col-md-8 col-12 row mxAuto paddingTop_3  ">
                <input
                  type="text"
                  name="message1"
                  value={this.state.message1}
                  className="col-9 mr-auto Radius_7 p-2  "
                  placeholder="Write Message"
                  onChange={this.onHandleChange}
                ></input>
                <button
                  className="btn orangecolor orangeborder col-2 ml-auto p-2"
                  onClick={() =>
                    ChatUser(
                      this.state.vendor_id,
                      this.state.user_id,
                      this.state.message1,
                      this.getMessages
                    )
                  }
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UserChat;
