import React, { useEffect } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";

const Covid19 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 mxAuto px-0">
              <div className="mask p-md-5 my-auto mxAuto ">
                <div className="col-12  mxAuto mb-5 px-auto">
                  <h1 className="font58 White pt-5">Covid 19</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-10 mxAuto mt-5 content maxWidth">
        <p className="font18">
          Due to the pandemic affecting how the industry operates, Black Booking
          has taken significant measures to ensure everyone's safety. The COVID
          19 guidelines specify how Proffesionals operators and customers will
          interact in the physical location.{" "}
        </p>
        <ul>
          <li className="font18">Wearing a mask is mandatory</li>
          <li className="font18">Disposable gloves are used</li>
          <li className="font18">Seating distance of six feet</li>
          <li className="font18">
            It is recommended not to book your appointment if you feel flu-like
            or have any other symptoms.
          </li>
          <li className="font18">
            Sanitizers must be used before entering the area
          </li>
          <li className="font18">
            Citizens must abide by the local law if authorities impose any
            further COVID 19 guidelines. Be safe, everyone!
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Covid19;
