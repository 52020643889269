import React from "react";
import { NavLink } from "react-router-dom";

export default function NewsRoomCards(props) {
  const { newsroom } = props;
  return (
    <>
      <NavLink
        to={"/blog/" + newsroom._id}
        className="col-12 col-sm-6 col-lg-4 mt-3 padBottom"
      >
        <div>
          <div className="card ">
            <h5 className="card-title">{newsroom.title}</h5>
            <img
              className="card-img-top newsimage"
              src={newsroom.images[0]}
              alt=""
              style={{ maxHeight: "220px" }}
            ></img>
            <p className="card-text add-three-dots ">{newsroom.description} </p>
          </div>
        </div>
      </NavLink>
    </>
  );
}
