import { message } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Subscription from "../../API/Subscription/Subscription";
import blacklogo from "../../assests/blacklogo.png";
const Footer = () => {
  const initialstate = {
    email: "",
    error: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  // eslint-disable-next-line
  const { email, error } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        Subscription(email);
        setFormData({
          ...FormData,
          email: "",
        });
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid");
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required");
    }
  };
  return (
    <>
      <div className="">
        <div className="col-11 mxAuto pt-5 pb-5 maxWidth ">
          <div className="row mxAuto ">
            <div className="col-lg-3 col-12 mxAuto d-inline-block">
              <NavLink to="/">
                <img src={blacklogo} alt=""></img>
              </NavLink>
              <p>
                Pre-book your appointments with nearby Professionals.
                Personalized Service Ensured.
              </p>
            </div>

            <div className="col-lg-6 col-12 d-inline-flex mxAuto">
              <ul className="mxAuto">
                <li className="list-unstyled ">
                  <NavLink to="/aboutus" className="Black font14">
                    About Us
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/contactus" className="Black font14">
                    Contact Us
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/privacypolicy" className="Black font14">
                    Privacy Policy
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/careers" className="Black font14">
                    {" "}
                    Careers
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/covid19" className="Black font14">
                    Covid-19
                  </NavLink>
                </li>
              </ul>

              <ul className="mxAuto">
                <li className="list-unstyled">
                  <NavLink to="/business" className="Black font14">
                    List Your Business
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/howto" className="Black font14">
                    How to list your Business
                  </NavLink>
                </li>
                <li className="list-unstyled">
                  <NavLink to="/blogs" className="Black font14">
                    Blog
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-12 pr-0 mxAuto">
              <h5 className="">Subscribe to News Letter</h5>

              <input
                type="email"
                className="form-control email rounded-0"
                placeholder="Email"
                name="email"
                value={email}
                onChange={onHandleChange}
              />

              <button
                className="btn Orange White mt-2 col-12 rounded-0"
                type="button"
                onClick={validation}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
        <div className="ft pt-3 row">
          <p className="col-12 mb-0 pt-3 pr-auto">
            {" "}
            Copyright &copy;{" "}
            <NavLink to="/" className="Black">
              Blackbooking.org
            </NavLink>{" "}
          </p>
          <p className="col-12 mb-0 pb-3 pl-auto">
            Website Designed &amp; Developed by
            <a href={"https://bizzclan.com"} target="/blank">
              {" "}
              Bizzclan
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
