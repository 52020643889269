// import React from "react";

import { Select } from "antd";

export const OPTIONS = [
  "00:00am",
  "00:15am",
  "00:30am",
  "00:45am",
  "01:00am",
  "01:15am",
  "01:30am",
  "01:45am",
  "02:00am",
  "02:15am",
  "02:30am",
  "02:45am",
  "03:00am",
  "03:15am",
  "03:30am",
  "03:45am",
  "04:00am",
  "04:15am",
  "04:30am",
  "04:45am",
  "05:00am",
  "05:15am",
  "05:30am",
  "05:45am",
  "06:00am",
  "06:15am",
  "06:30am",
  "06:45am",
  "07:00am",
  "07:15am",
  "07:30am",
  "07:45am",
  "08:00am",
  "08:15am",
  "08:30am",
  "08:45am",
  "09:00am",
  "09:15am",
  "09:30am",
  "09:45am",
  "10:00am",
  "10:15am",
  "10:30am",
  "10:45am",
  "11:00am",
  "11:15am",
  "11:30am",
  "11:45am",
  "12:00pm",
  "12:15pm",
  "12:30pm",
  "12:45pm",
  "1:00pm",
  "1:15pm",
  "1:30pm",
  "1:45pm",
  "2:00pm",
  "2:15pm",
  "2:30pm",
  "2:45pm",
  "3:00pm",
  "3:15pm",
  "3:30pm",
  "3:45pm",
  "4:00pm",
  "4:15pm",
  "4:30pm",
  "4:45pm",
  "5:00pm",
  "5:15pm",
  "5:30pm",
  "5:45pm",
  "6:00pm",
  "6:15pm",
  "6:30pm",
  "6:45pm",
  "7:00pm",
  "7:15pm",
  "7:30pm",
  "7:45pm",
  "8:00pm",
  "8:15pm",
  "8:30pm",
  "8:45pm",
  "9:00pm",
  "9:15pm",
  "9:30pm",
  "9:45pm",
  "10:00pm",
  "10:15pm",
  "10:30pm",
  "10:45pm",
  "11:00pm",
  "11:15pm",
  "11:30pm",
  "11:45pm",
];

const TimeSlot = ({ selected, handleTimeChange }) => {
  const handleChange = (selectedItems) => {
    handleTimeChange("noncheck", selectedItems);
  };

  const filteredOptions = OPTIONS.filter((o) => !selected?.includes(o));

  return (
    <Select
      mode="multiple"
      placeholder="Time Slots"
      value={selected}
      onChange={handleChange}
      style={{ width: "100%", background: "grey" }}
    >
      {filteredOptions.map((item) => (
        <Select.Option key={item} value={item} className="col-12 text-center">
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TimeSlot;
