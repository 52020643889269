import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";
import ReactPhoneInput from "react-phone-input-2";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import Contact from "../API/ContactUs/Contact";
const ContactUs = () => {
  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    error: "",
  };

  const [FormData, setFormData] = useState(initialstate);
  const { first_name, last_name, email, phone, message, error } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
      error: "",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validation = () => {
    if (first_name !== "") {
      if (last_name !== "") {
        if (email !== "") {
          if (email.includes("@") && email.includes(".")) {
            if (phone !== "") {
              if (message !== "") {
                Contact(
                  first_name,
                  last_name,
                  phone,
                  message,
                  email,
                  initialstate,
                  setFormData
                );
              } else {
                setFormData({
                  ...FormData,
                  error: "Provide Your message",
                });
              }
            } else {
              setFormData({
                ...FormData,
                error: "Phone Number is Required",
              });
            }
          } else {
            setFormData({
              ...FormData,
              error: "Email is not valid",
            });
          }
        } else {
          setFormData({
            ...FormData,
            error: "Enter Your Email",
          });
        }
      } else if (last_name === "") {
        setFormData({
          ...FormData,
          error: "Last Name is Required",
        });
      }
    } else if (first_name === "") {
      setFormData({
        ...FormData,
        error: "First Name is Required",
      });
    }
  };

  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid col-12 mxAuto">
          <div className="row">
            <div className="col-12 mxAuto px-0">
              <div className="mask p-md-5 my-auto mxAuto ">
                <div className="col-12 mxAuto mb-5 px-auto">
                  <h1 className="White pt-5 font58">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="maxWidth">
        <div className="row col-11 mxAuto pt-5">
          <div className="col-12 col-lg-5 mxAuto p-5">
            <h3 className=" pb-4 Black">Contact info</h3>
            <p className="font14 Black pb-5">
              Our team is available to answer any questions you may have.
            </p>

            <p className="pb-4 font19to11 Black">
              <EnvironmentOutlined className="pr-2" /> London Street No.9
            </p>
            <p className="pb-4 font19to11 Black">
              {" "}
              <PhoneOutlined className="pr-2" /> +92 123456789
            </p>
            <p className="pb-5 font19to11 Black">
              {" "}
              <InboxOutlined className="pr-2" /> blackbookingblack@gmail.com
            </p>
          </div>

          <div className="col-12 col-lg-6 mxAuto bg-white contact rounded-0 shadow">
            <form className="col-11 mx-auto paddingTop_3">
              <h3 className=" text-center">Get in Touch</h3>
              <p className=" Black">
                Black Booking Team will respond at the earliest opportunity
                after you fill out the form below.
              </p>
              <hr></hr>
              <div className="row">
                <div className="col-12 col-md-6 px-0 pr-md-1 mt-1">
                  <input
                    type="text"
                    className="form-control rounded-0 fillcolor"
                    placeholder="First name"
                    name="first_name"
                    value={first_name}
                    onChange={onHandleChange}
                  ></input>
                </div>
                <div className="col-12 col-md-6 px-0 pl-md-1 mt-1">
                  <input
                    type="text"
                    className="form-control rounded-0 fillcolor"
                    placeholder="Last name"
                    name="last_name"
                    value={last_name}
                    onChange={onHandleChange}
                  ></input>
                </div>
              </div>

              <div className="row pt-1">
                <div className="col-12 col-md-6 px-0 pr-md-1 mt-1">
                  <input
                    type="text"
                    className="form-control rounded-0 fillcolor"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={onHandleChange}
                  ></input>
                </div>
                <div className="col-12 col-md-6 px-0 pl-md-1 mt-1 phone">
                  <ReactPhoneInput
                    inputExtraProps={{
                      name: "contact",
                      required: true,
                      autoFocus: true,
                    }}
                    defaultCountry={"sg"}
                    value={phone}
                    onChange={(text) =>
                      setFormData({
                        ...FormData,
                        phone: text,
                      })
                    }
                  />
                  {/* <input
                    type="text"
                    className="form-control rounded-0 fillcolor"
                    placeholder="Phone"
                    name="phone"
                    value={phone}
                    onChange={onHandleChange}
                  ></input> */}
                </div>
              </div>

              <div className="col-12 px-0 pt-2">
                <textarea
                  type="text"
                  className="form-control ProfileArea rounded-0 fillcolor"
                  placeholder="Your Message"
                  name="message"
                  value={message}
                  onChange={onHandleChange}
                  rows={8}
                ></textarea>
              </div>
              <button
                className="btn Orange White p-2 mt-3 col-12 rounded-0"
                type="button"
                onClick={validation}
              >
                Submit
              </button>
              <p
                className="text-danger text-center mb-0 py-2"
                style={{ fontSize: "20px", fontWeight: "500" }}
              >
                {" "}
                {error}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
