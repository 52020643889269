import San_Francisco from "../../assests/alexandria.jpg";
import Boston from "../../assests/ohio.jpg";
import Chicago from "../../assests/newMaxico.jpg";
import Los_Angeles from "../../assests/california.jpg";
import Atlanta from "../../assests/wyoming.jpg";
import newyork from "../../assests/newyork.jpg";
import Miami from "../../assests/greenville.jpg";
import Dallas from "../../assests/florida.jpg";

const placesData = [
  {
    _id: "1",
    image: newyork,
    name: "New York",
    coordinates: {
      lati: 40.711391223589956,
      long: -74.00283887121081,
    },
  },
  {
    _id: "2",
    image: Los_Angeles,
    name: "Los Angeles",
    coordinates: {
      lati: 34.0534814035503,
      long: -118.22290500559876,
    },
  },
  {
    _id: "3",
    image: Atlanta,
    name: "Atlanta",
    coordinates: {
      lati: 33.76257548347483,
      long: -84.41270754333651,
    },
  },
  {
    _id: "4",
    image: Miami,
    name: "Miami",
    coordinates: {
      lati: 25.792917990688007,
      long: -80.21692717848322,
    },
  },
  {
    _id: "5",
    image: San_Francisco,
    name: "San Francisco",
    coordinates: {
      lati: 37.756885694690304,
      long: -122.44463812453552,
    },
  },
  {
    _id: "6",
    image: Boston,
    name: "Boston",
    coordinates: {
      lati: 42.335671777723164,
      long: -71.06401113011243,
    },
  },
  {
    _id: "7",
    image: Chicago,
    name: "Chicago",
    coordinates: {
      lati: 41.87873863570801,
      long: -87.69557176525404,
    },
  },
  {
    _id: "8",
    image: Dallas,
    name: "Dallas",
    coordinates: {
      lati: 32.88757562458397,
      long: -96.50701949302109,
    },
  },
];

export default placesData;
