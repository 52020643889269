import React, { useState } from "react";
import OtpInput from "react-otp-input";

const CodeValidatiion = () => {
  const initialstate = {
    otp: "",
  };

  const [FormData, setFormData] = useState(initialstate);

  const { otp } = FormData;

  const onHandleChange = (otp) => {
    setFormData({
      ...FormData,
      otp: otp,
    });
  };

  return (
    <>
      <div className="row Center">
        <div className="mxAuto">
          <p className="font20">Enter the Code</p>
          <OtpInput
            value={otp}
            onChange={onHandleChange}
            numInputs={6}
            separator={<span>-</span>}
          />
        </div>
      </div>
    </>
  );
};
export default CodeValidatiion;
