import axios from "axios";
const Reviews = (vendor_id, user_id, stars, reviews) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/review/` +
    vendor_id +
    `/` +
    user_id;
  axios
    .post(URL, {
      review: reviews,
      stars: stars,
    })
    .then((res) => {});
};

export default Reviews;
