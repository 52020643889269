import { message } from "antd";
import axios from "axios";

const Payout = (id, email) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/stripe_account/` + id;
  axios
    .put(URL, {
      email: email,
    })
    .then((res) => {
      console.log("Res in payaout",res,);
      if (res.data.success) {
        message.success(res.data.message);
        window.location = res.data.redirect.url;
      } else {
        message.error(res.data.message);
      }
    });
};

export default Payout;
