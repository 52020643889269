import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import ReportsComponent from "../SubComponents/ReportsComponent";
import ReviewComponent from "../SubComponents/ReviewComponent";
import { Spin } from "antd";
import axios from "axios";

const ReviewandReports = () => {
  let id = localStorage.getItem("venderId");
  const initialstate = {
    reviews: [],
    reporting: [],
    update: false,
  };

  const [FormData, setFormData] = useState(initialstate);
  const { reviews, reporting, update } = FormData;

  useEffect(() => {
    getReviews();
    // eslint-disable-next-line
  }, []);

  const getReviews = () => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/reviews/` + id;

    axios.get(link).then((response) => {
      getReports(response);
      if (response.data.success) {
        setFormData({
          ...FormData,
          reviews: response.data.data.reviews,
        });
      } else {
      }
    });
  };

  const getReports = (resOlder) => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/all_reports/` + id;

    axios.get(link).then((response) => {
      if (response.data.success) {
        setFormData({
          ...FormData,
          reporting: response.data.data.reports,
          reviews: resOlder.data.data.reviews,
          update: true,
        });
      }
    });
  };

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-10 mxAuto">
          <h1 className="text-center font29 pt-5">Reviews &#38; Reports</h1>
          <h3>Reviews</h3>
          <ReviewComponent data={reviews} />

          <h3>Reports</h3>
          <ReportsComponent data={reporting} />
        </div>
      </>
    );
  }
};

export default ReviewandReports;
