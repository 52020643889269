import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const LoginSecurity = (current_password, password, confirmPwd) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/update_password/` +
    localStorage.getItem("venderId");
  axios
    .put(URL, {
      current_password: current_password,
      password: password,
      confirmPwd: confirmPwd,
    })
    .then((res) => {
      if (res.data.success) {
        if (res.data.data.password !== confirmPwd) {
          message.error("Password does not match");
        } else {
          message.success("Password Updated Successfully");
          window.location = "/security/" + localStorage.getItem("venderId");
          $("#pwd").addClass("d-none");
        }
      } else {
        message.error(res.data.message);
      }
    });
};

export default LoginSecurity;
