import React, { useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { message } from "antd";
import { useParams } from "react-router-dom";

const ResetPasswordUser = () => {
  const initialstate = {
    new_password: "",
    confirm_password: "",
  };

  const params = useParams();
  const [FormData, setFormData] = useState(initialstate);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmNewPassword, setconfirmNewPassword] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    const URL = `${process.env.REACT_APP_BASE_URL}/api/user/reset_password/${params._id}`;
    axios
      .put(URL, {
        new_password: FormData.new_password,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Your Password is Reset!");
          window.location = "/customerlogin";
        } else {
          message.error(res.data.message);
        }
      });
  };

  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      ></div>
      <div className=" col-lg-6 col-md-9 col-11 mxAuto bg-white  loginform">
        <h1 className="text-center pt-5 pb-4">Reset Password</h1>
        <hr className="col-11 mxAuto mb-4"></hr>
        <form onSubmit={submitHandler}>
          <div className="col-11 mxAuto">
            <label className="form-label">New Password</label>
            <div
              className="col-12 bordercolor loginemail fillcolor rounded-top px-0 px-3"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <input
                type={newPassword ? "text" : "password"}
                placeholder="New Password"
                required
                className="w-100 border-0 fillcolor"
                name="password"
                onChange={(e) => {
                  setFormData({ ...FormData, new_password: e.target.value });
                }}
              />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setNewPassword(!newPassword)}
              >
                {newPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </span>
            </div>
          </div>
          <div className="col-11 mxAuto mt-2">
            <label className="form-label">Confirm New Password</label>
            <div
              className="col-12 bordercolor loginemail fillcolor rounded-top px-0 px-3"
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <input
                type={confirmNewPassword ? "text" : "password"}
                placeholder="Confirm new Password"
                required
                className="w-100 border-0 fillcolor"
                name="password"
                onChange={(e) => {
                  setFormData({
                    ...FormData,
                    confirm_password: e.target.value,
                  });
                }}
              />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setconfirmNewPassword(!confirmNewPassword)}
              >
                {confirmNewPassword ? (
                  <EyeInvisibleOutlined />
                ) : (
                  <EyeOutlined />
                )}
              </span>
            </div>
          </div>
          <div className="form-group col-11 mxAuto pt-3">
            <button type="submit" className="signupbtn mt-3">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordUser;
