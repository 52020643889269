import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const LegalName = (first_name, last_name) => {
  if (first_name !== "" || last_name !== "") {
    const URL =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/name/` +
      localStorage.getItem("venderId");
    axios
      .put(URL, {
        first_name: first_name,
        last_name: last_name,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Name updated successfuly");
          $("#name").addClass("d-none");
          $("#editName").removeClass("d-none");
          $("#first_name").prop("disabled", true);
          $("#last_name").prop("disabled", true);
        } else {
          message.error(res.data.message);
        }
      });
  } else {
    message.error("Please provide both first name and last name");
  }
};

export default LegalName;
