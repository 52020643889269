import React from "react";

import { NavLink } from "react-router-dom";
import { StarFilled } from "@ant-design/icons";
import wifi from "../../assests/wifi.svg";
import no_signal from "../../assests/no-signal.svg";
import instant from "../../assests/instant.svg";
import travelToMe from "../../assests/travelToMe.svg";

export default function SaloonMapView(props) {
  console.log(props);
  const { saloonmapview } = props;
  var stars = 0;
  // eslint-disable-next-line
  saloonmapview.reviews.map((review) => {
    stars = stars + review.stars;
  });
  stars = stars / saloonmapview.reviews.length;
  return (
    <>
      <div className="col-12 mt-3 mx-auto ">
        <NavLink to={"/saloonDetail/" + saloonmapview._id}>
          <div className="row mt-3 listingItem">
            <div className="col-md-5 col-lg-4 px-0 col-12 listingItem listingItem_img">
              <img
                src={saloonmapview.images[0]}
                alt="img"
                className="thumbNail"
              ></img>
            </div>
            <div className="col-md-7 col-lg-8 col-12 listingItem Align_middle pt-3 px-0 px-md-3 pt-md-0 ">
              <div>
                <h3 className="py-0 mr-auto font20">
                  {saloonmapview.business_information.business_name}
                </h3>
                <p className="mb-0 mr-3 font16 greyColor font-weight-light">
                  {saloonmapview.business_information.State} ,{" "}
                  {saloonmapview.business_information.city}
                </p>
                <div className="mr-auto pt-2">
                  <StarFilled className="my-auto" />
                  {saloonmapview.reviews.length < 1 ? " 0 " : stars}({" "}
                  {saloonmapview.reviews.length} review )
                </div>

                <img
                  src={saloonmapview.wifi === true ? wifi : no_signal}
                  title={
                    saloonmapview.wifi === true
                      ? "This Professional offers WiFi"
                      : "This Professional Doesn't offer WiFi"
                  }
                  className="pt-2 pt-md-5 mr-4"
                  alt=""
                  style={{ width: "17px" }}
                ></img>
                {saloonmapview.instant_booking === true ? (
                  <img
                    src={instant}
                    title="This Professional is offering Instant Booking"
                    className="pt-2 pt-md-5 mr-4"
                    alt=""
                    style={{ width: "17px" }}
                  ></img>
                ) : null}
                <img
                  src={saloonmapview.trevel_to_me === true ? travelToMe : ""}
                  title={
                    saloonmapview.trevel_to_me === true
                      ? "This Professional is offering Serivce at Door Step"
                      : ""
                  }
                  className="pt-2 pt-md-5 mr-4"
                  alt=""
                  style={{ width: "17px" }}
                ></img>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}
