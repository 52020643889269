import React, { useEffect } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="mask p-md-5 my-auto mxAuto ">
            <div className="col-12  mxAuto mb-5 px-auto">
              <h1 className="font58 White pt-5">About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid maxWidth mxAuto mt-5 ">
        <p className="font18 text-justify">
          Black Booking is a platform that makes booking your usual Beauty /
          Grooming appointments hassel-free. Using our service, local businesses
          have been able to increase the number of bookings they recieve while
          gaining access to new clients.
        </p>
        <p className="font18 text-justify">
          As for customers you will have the ability to find and book a Mua,
          Hairstylist , Professional , Lash and or Nail technician in the area
          you’re located or the one you’re heading to in just 5 clicks.
        </p>
        <p className="font18 text-justify">
          Black Booking is here to make booking as well as hosting appointments
          extremely simple.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
