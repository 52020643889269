import React, { useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import VendorInvite from "../../API/InviteFriends/VendorInvite";
import { message } from "antd";
const InviteFriends = () => {
  let vendor_id = localStorage.getItem("venderId");
  const initialstate = {
    email: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  const { email } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        VendorInvite(email, vendor_id);
        // setFormData({
        //   ...FormData,
        // });
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid");
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required");
    }
  };
  return (
    <div>
      <BlackNavbar />
      <div className="container col-11 mxAuto pt-5 maxWidth">
        <h1 className="font39 col-md-7 col-11">
          Invite friends to join the Blackbooking community
        </h1>
        <div className="col-md-6 col-12 row bordercolor fillcolor ">
          <input
            type="email"
            placeholder="Enter Email Address"
            className="col-12 border-0 fillcolor p-2"
            name="email"
            value={email}
            onChange={onHandleChange}
          ></input>
        </div>
        <button
          className="btn White Orange mt-2 col-md-6 col-12 rounded-0"
          onClick={validation}
        >
          Invite
        </button>
      </div>
    </div>
  );
};

export default InviteFriends;
