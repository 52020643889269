import React from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";

const TermsandCondition = () => {
  return (
    <div>
      <BlackNavbar />

      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-11 mxAuto px-0">
              <div className="mask p-md-5 my-auto mxAuto ">
                <div className="col-12  mxAuto mb-5 px-auto">
                  <h1 className="texth pt-5">Terms & Condition</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-10 mxAuto mt-5">
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea officia
          voluptas nobis dignissimos perspiciatis illo odio ipsum, sed quibusdam
          placeat qui molestias mollitia eaque assumenda accusamus enim est
          architecto officiis aliquam quisquam ducimus exercitationem dicta!
          Nobis aut nemo tempore, voluptatem culpa sit fugiat sunt dolor,
          asperiores inventore exercitationem ipsam! Dolore ipsum alias iusto
          nesciunt ipsa labore pariatur voluptatum reprehenderit eveniet.
        </p>
      </div>
    </div>
  );
};

export default TermsandCondition;
