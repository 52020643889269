import React from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
const PrivacyandSharing = () => {
  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <p className="White pt-5  font17">
            <NavLink to="/account" className="White">
              Account
            </NavLink>{" "}
            &gt; Privacy & Sharing
          </p>
          <h1 className="White  pb-3 font39">Privacy & Sharing</h1>
        </div>
      </div>

      <div className=" pt-5 content maxWidth">
        <p>
          The Privacy Policy of Black Booking is open to all stakeholders. The
          use of this platform indicates your agreement with this Privacy
          Policy.
        </p>
        <h3>INFORMATION COLLECTED</h3>
        <p>
          The Company may require you to provide it with personal contact
          information, such as your name, company name, address, phone number,
          and email address when requesting additional information about the
          Services or registering to use the Platform.
        </p>
        <p>
          Depending on the services you choose, the website may also require
          that you provide billing information, such as billing name and
          address, credit card number.
        </p>
        <p>
          The website may collect information through the use of commonly-used
          tools such as cookies for web navigation information. Site
          navigational information includes standard information from your Web
          browser (such as Web browser type and language), your Internet
          Protocol ("IP") address, and the actions you take on the Website (such
          as viewing pages and clicking links).{" "}
        </p>

        <h3>Information Processing</h3>
        <p>The company will process the information for:</p>
        <p>Contacting the relevant stakeholder</p>
        <p>Transferring the booking information to the relevant parties</p>
        <p>Data storage for future use</p>
        <p>
          Analyses of Web Navigational behavior for performance optimization
        </p>
        <p>
          All of the data are collected with explicit consent from all parties.
          It can be deleted and removed upon request. Contact us if you have any
          concerns about information collection and processing.{" "}
        </p>
      </div>
    </div>
  );
};

export default PrivacyandSharing;
