import React from "react";

import { StarFilled } from "@ant-design/icons";
import wifi from "../../assests/wifi.svg";
import no_signal from "../../assests/no-signal.svg";
import instant from "../../assests/instant.svg";
import travelToMe from "../../assests/travelToMe.svg";
import { NavLink } from "react-router-dom";

export default function BusinessListingComponent(props) {
  const { businesslisting } = props;

  return (
    <div className=" col-10 col-md-3 mt-3 bsn" style={{ minHeight: "230px" }}>
      <NavLink to={"/saloonDetail/" + businesslisting._id}>
        <div className=" mt-3 mr-0">
          <img
            src={businesslisting.images[0]}
            alt="Pic"
            className="mr-3 w-100"
            style={{ minHeight: "230px", maxHeight: "230px" }}
          ></img>
          <div>
            <div className="row d-inline-flex mt-2 pt-2">
              <StarFilled className="my-auto" />
              <div className="ml-2 mr-5 d-inline">
                ({businesslisting.reviews.length})
              </div>
            </div>

            <h5 className="mb-0 mr-3 font19">
              {businesslisting.business_information.business_name}
            </h5>
            <p className="mb-0 mr-3 font16 greyColor font-weight-light">
              {businesslisting.business_information.State} ,{" "}
              {businesslisting.business_information.city}
            </p>
            <img
              src={businesslisting.wifi === true ? wifi : no_signal}
              title={
                businesslisting.wifi === true
                  ? "This Professional offers WiFi"
                  : "This Professional Doesn't offer WiFi"
              }
              className="pt-2 pt-md-3 mr-4"
              alt=""
              style={{ width: "17px" }}
            ></img>
            <img
              src={businesslisting.instant_booking === true ? instant : ""}
              title={
                businesslisting.instant_booking === true
                  ? "This Professional is offering Instant Booking"
                  : ""
              }
              className="pt-2 pt-md-3 mr-4"
              alt=""
              style={{ width: "17px" }}
            ></img>
            <img
              src={businesslisting.trevel_to_me === true ? travelToMe : ""}
              title={
                businesslisting.trevel_to_me === true
                  ? "This Professional is offering Serivce at Door Step"
                  : ""
              }
              className="pt-2 pt-md-3 mr-4"
              alt=""
              style={{ width: "17px" }}
            ></img>
          </div>
        </div>
      </NavLink>
    </div>
  );
}
