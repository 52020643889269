import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import BookingTable from "./BookingTable";

const Booking = () => {
  let id = localStorage.getItem("venderId");
  const initialstate = {
    All: [],
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { All, update } = FormData;
  const getAll = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/all_bookings/` + id;

    axios.get(link).then((response) => {
      if (response.data.success) {
        const sortData = response.data.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setFormData({
          ...FormData,
          All: sortData,
          update: true,
        });
      } else {
        setFormData({
          ...FormData,
          All: [],
          update: true,
        });
      }
    });
  };
  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);
  var Earning = 0;
  // eslint-disable-next-line
  All.map((review) => {
    if (review.booking_status === "Completed") {
      Earning = Earning + review.total_bill;
    }
  });

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-10 mxAuto">
          <h1 className="text-center font29 pt-5">Bookings</h1>
          <p className="float-right">Total Earning: ${Earning}</p>
          <BookingTable data={All} getAll={getAll} />
        </div>
      </>
    );
  }
};

export default Booking;
