import { message } from "antd";
import axios from "axios";
const VendorInvite = (email, vendor_invite) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/comunity_invitation/${vendor_invite}`;
  axios
    .post(URL, {
      email: email,
    })
    .then((res) => {
      console.log(res);
      if (res.data.success) {
        message.success("Invitation sent");
        window.location = "/";
      } else {
        console.log("Hey");
        message.error(res.data.error);
      }
    });
};

export default VendorInvite;
