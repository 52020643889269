import React, { useEffect, useState } from "react";

import Avatar from "antd/lib/avatar/avatar";
import { useParams } from "react-router-dom";
import axios from "axios";
import userImg from "../assests/user.svg";
import protectedImg from "../assests/protectedImg.png";
import BlackNavbar from "./SubComponents/BlackNavbar";
import ReviewsComponent from "./SubComponents/ReviewsComponent";
import { SafetyCertificateFilled, StarFilled } from "@ant-design/icons";
import { message, Spin } from "antd";
import SaloonDetailMap from "./SaloonDetailMap";
import { Carousel } from "antd";
import { Modal } from "antd";
export default function SaloonDetail(props) {
  const initialstate = {
    first_name: "",
    last_name: "",
    numReviews: "45",
    booking_date: "",
    booking_time: "",
    business_name: "",
    website: "",
    city: "",
    State: "",
    personName: "John",
    dates: "25/10/2020",
    services: [],
    reviews: [],
    images: [],
    lat: "",
    lng: "",
    venderData: [],
    Updated: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const {
    business_name,
    city,
    services,
    reviews,
    images,
    venderData,
    Updated,
  } = FormData;
  const { _id } = useParams();
  let vendor_id = _id;
  useEffect(() => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/` + vendor_id;
    axios.get(link).then((res) => {
      if (res.data.success) {
        setFormData({
          ...FormData,
          business_name: res.data.data.business_information.business_name,
          city: res.data.data.business_information.city,
          State: res.data.data.business_information.State,
          lat: res.data.data.lat,
          lng: res.data.data.lng,
          services: res.data.data.services,
          reviews: res.data.data.reviews,
          images: res.data.data.images,
          venderData: res.data.data,
          Updated: true,
        });
      }
    });
    // eslint-disable-next-line
  }, []);
  var stars = 0;
  // eslint-disable-next-line
  reviews.map((review) => {
    stars = stars + review.stars;
  });
  stars = stars / reviews.length;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const RenderItems = services.map((item) => {
    return (
      <>
        <tr>
          <td className="text-left">{item.service_name} </td>
          <td className="text-right">${item.service_price}</td>
        </tr>
      </>
    );
  });
  const RenderReviews = reviews.map((review) => {
    return (
      <ReviewsComponent key={review._id} review={review}></ReviewsComponent>
    );
  });
  const Booking = () => {
    const type = localStorage.getItem("type");

    if (type === "user") {
      window.location = "/paymentbooking/" + _id;
    } else {
      message.error("Login as User first");
      window.open("/customerlogin", "_blank");
    }
  };

  if (Updated === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-11 mxAuto maxWidth pt-5">
          <div className="col-12 mxAuto">
            <p className="font20 mb-0" style={{ fontWeight: "700" }}>
              {business_name}
            </p>
            <p className="my-auto pl-3">
              {city} , {venderData.business_information.State}
            </p>
            <div className="row pl-3">
              <StarFilled className="my-auto" />
              <h6 className="my-auto px-1">
                {reviews.length < 1 ? "0  " : stars}
              </h6>
              <h6 className="my-auto">({reviews.length} reviews)</h6>
            </div>
            <div className="col-12 mxAuto row paddingTop_3 PadBottom">
              <div className="row img-border">
                <div
                  className="col-12 col-md-6 pl-0 pr-0 pr-md-1"
                  style={{ maxHeight: "50vh" }}
                >
                  <img
                    src={images[0]}
                    alt=""
                    className="mapimg mxAuto h-100 w-100"
                  ></img>
                </div>
                <div
                  className="col-md-6 DisplayNoneonSmall p-0"
                  style={{ maxHeight: "50vh" }}
                >
                  <div className="row h-100">
                    {images.slice(1, 5).map((img, index) => {
                      return (
                        <>
                          <img
                            src={img}
                            alt=""
                            className={`mapimg w-100 h-50 col-6 mxAuto ${
                              index % 2 === 0 ? "pr-1 pl-0" : "pl-1 pr-0"
                            } ${index <= 1 ? "pb-1" : "pt-1"}`}
                          ></img>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 btnImg">
                <button
                  className="btn Orange Radius_7 p-1 White px-3 py-2"
                  onClick={showModal}
                >
                  See All Photos
                </button>
              </div>

              <Modal
                title={business_name + " Images"}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
              >
                {" "}
                {/* //width="80%" */}
                <Carousel autoplay>
                  {images.map((img) => {
                    return <img src={img} alt="" className="w-100"></img>;
                  })}
                </Carousel>
              </Modal>
            </div>
            <div className="pt-3 row">
              <div className="col-12 col-md-8 col-lg-7">
                <h5>
                  Price Listed by{" "}
                  {venderData.first_name + " " + venderData.last_name}
                </h5>
                <hr></hr>
              </div>

              <div className="col-12 col-md-8 col-lg-7">
                <table className="table table-striped">
                  <tbody>{RenderItems}</tbody>
                </table>
              </div>
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn ml-auto d-block Orange White Radius_10 my-2 "
                onClick={Booking}
              >
                Book Now
              </button>
            </div>

            <hr className="PadBottom"></hr>
            <div className="col-12 mxAuto PadBottom">
              <h4>Reviews</h4>
              <div className="row pt-5">
                {reviews.length < 1 ? <p>No Reviews Yet</p> : RenderReviews}

                <hr></hr>
              </div>
            </div>
            <div className="col-12 pt-4 PadBottom">
              <h6>Location</h6>
              <p>{venderData.business_information.address_line_1}</p>
              {venderData.lat && venderData.lng ? (
                <SaloonDetailMap
                  google={props.google}
                  center={{
                    lat: venderData.lat,
                    lng: venderData.lng,
                  }}
                  height="300px"
                  zoom={15}
                />
              ) : (
                "No Map"
              )}
              <hr></hr>
            </div>

            <div className="col-12 row PadBottom">
              <div className="col-12 col-md-4 d-inline-flex">
                <div className="mr-3">
                  <Avatar src={userImg} />
                </div>
                <div>
                  <p className="mb-0">Owner: {venderData.first_name}</p>
                  <p className="mr-auto">
                    {" "}
                    <StarFilled className="my-auto" /> {reviews.length} Reviews
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 mr-auto">
                <p>
                  {" "}
                  <Avatar src={protectedImg} /> Identified Verified
                </p>
              </div>

              <div className="col-12 col-md-4 ml-auto d-inline-flex">
                <SafetyCertificateFilled className="my-auto" />
                <div>
                  <p className="mb-0 ml-3">
                    To protect your payment, never transfer money or communicate
                    outside of the BlackBooking website.
                  </p>
                </div>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>
      </>
    );
  } else if (Updated !== true) {
    return <Spin size="large" />;
  }
}
