import React from "react";
import featuredBg from "../../../assests/featuredBg.jpg";
import { message } from "antd";
import Geocode from "react-geocode";
import axios from "axios";
import BlackNavbar from "../../SubComponents/BlackNavbar";
import EditBussinessForm from "../../SubComponents/ListYourBussiness/EditBussinessForm";
import RenderMapComp from "../../SubComponents/ListYourBussiness/RenderMap";

const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/all_vendors`;

class EditYourBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      Newimages: [],
      VerificationImages: [],
      first_name: "",
      last_name: "",
      email: "",
      contact: "",
      password: "",
      payment_type: "",
      paypalEmail: "",
      business_name: "",
      website: "",
      city: "",
      State: "",
      lat: "",
      lng: "",
      lati: "",
      long: "",
      mapLat: "",
      mapLng: "",
      markerLat: "",
      markerLng: "",
      address_line_1: "",
      address_line_2: "",
      service_name: "",
      service_price: "",
      service_time: "",
      filteredServices: [],
      services: [],
      working_hours: [],
      WiFi: false,
      Book: false,
      trevel_to_me: false,
      Facebook: "",
      Instagram: "",
      error: "",
      openModel: false,
      loading: true,
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.imagesSet = this.imagesSet.bind(this);
    this.makeImageArray = this.makeImageArray.bind(this);
  }
  working_hours_close = (index) => {
    let working_hours = this.state.working_hours;
    working_hours[index].closed = !working_hours[index].closed;
    this.setState({ working_hours });
  };

  working_hours_time = (index, time, date) => {
    let working_hours = this.state.working_hours;
    working_hours[index].time = time;
    working_hours[index].date = date;
    this.setState({ working_hours });
  };

  getAllVendors = () => {
    axios.get(URL).then((res) => {
      if (res.data.success) {
        const id = this.props.location.pathname.slice(11);
        const singleVendor = res.data.data.find(({ _id }) => _id === id);
        this.setState({
          images: singleVendor.images,
          VerificationImages: singleVendor.verification_image,
          first_name: singleVendor.first_name,
          last_name: singleVendor.last_name,
          email: singleVendor.email,
          contact: singleVendor.contact,
          payment_type: singleVendor.payment_type,
          paypalEmail: singleVendor.paypal_email,
          business_name: singleVendor.business_information.business_name,
          website: singleVendor.business_information.website,
          city: singleVendor.business_information.city,
          State: singleVendor.business_information.State,
          lat: singleVendor.lat,
          lng: singleVendor.lng,
          lati: singleVendor.lat,
          long: singleVendor.lng,
          address_line_1: singleVendor.business_information.address_line_1,
          address_line_2: singleVendor.business_information.address_line_2,
          password: singleVendor.password,
          services: singleVendor.services,
          working_hours: singleVendor.working_hours,
          WiFi: singleVendor.wifi,
          Book: singleVendor.instant_booking,
          trevel_to_me: singleVendor.trevel_to_me,
          Facebook: singleVendor.facebook_link,
          Instagram: singleVendor.instagram_link,
        });
        console.log(this.state);
      } else {
        message.error(res.data.message);
      }
    });
  };

  componentDidMount() {
    this.getAllVendors();
    this.addressChange(this.state.markerLat, this.state.markerLng);
  }

  makeImageArray = (img) => {
    this.state.images.push(img);
    this.setState({
      error: "",
    });
  };

  onHandleImageChange = (file) => {
    this.setState({
      Newimages: this.state.Newimages.concat(file),
    });
  };

  imagesSet = (imageArray) => {
    this.setState({ images: [] });
    // eslint-disable-next-line
    imageArray.map((detail) => {
      this.makeImageArray(detail.originFileObj);
    });
  };

  makeVeriArray = (img) => {
    this.setState({
      error: "",
    });
    this.state.VerificationImages.push(img);
  };

  verification = (imageArray) => {
    this.setState({ VerificationImages: [] });
    // eslint-disable-next-line
    imageArray.map((detail) => {
      this.makeVeriArray(detail.originFileObj);
    });
  };

  onNumberChange = (value) => {
    this.setState({
      contact: value,
    });
  };

  onHandleChange = (event) => {
    event.preventDefault();
    const { name, type, checked } = event.target;
    if (type === "checkbox") {
      this.setState({
        [name]: checked,
        error: "",
      });
    } else if (event.target.name === "first_name") {
      this.setState({
        first_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "last_name") {
      this.setState({
        last_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "contact") {
      this.setState({
        contact: event.target.value.replace(/[^0-9]/gi, ""),
      });
    } else if (event.target.name === "service_name") {
      this.setState({
        service_name: event.target.value.replace(/[^a-zA-Z\s]/gi, ""),
      });
    } else if (event.target.name === "service_price") {
      if (parseInt(event.target.value) < parseInt(this.state.service_deposit)) {
        message.error("Service price cannot be less than deposit fee");
      } else {
        this.setState({
          service_price: event.target.value.replace(/[^0-9]/gi, ""),
        });
      }
    } else if (event.target.name === "service_time") {
      const value = Math.max(
        1,
        Number(event.target.value.replace(/[^0-9]/gi, ""))
      );
      this.setState({
        service_time: value,
      });
    } else if (event.target.name === "service_deposit") {
      if (parseInt(event.target.value) > parseInt(this.state.service_price)) {
        message.error("Deposit fee cannot be greator than service price");
      } else {
        this.setState({
          service_deposit: event.target.value.replace(/[^0-9]/gi, ""),
        });
      }
    } else if (event.target.name === "service_description") {
      this.setState({
        service_description: event.target.value,
      });
    } else {
      this.setState({
        [name]: event?.currentTarget?.value,
        error: "",
      });
    }
  };

  onWiFiChange = (e) => {
    this.setState({
      WiFi: e,
      error: "",
    });
  };

  onBookChange = (e) => {
    this.setState({
      Book: e,
      error: "",
    });
  };

  onTravelChange = (e) => {
    this.setState({
      trevel_to_me: e,
      error: "",
    });
  };

  addressChange = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response?.results[0]?.formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address_line_1: address ? address : "",
          city: city ? city : "",
          State: state ? state : "",
          markerLat: lat,
          markerLng: lng,
          mapLat: lat,
          mapLng: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // Abdul Hai Code Start
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.addressChange(newLat, newLng);
  };

  onPlaceChange = (place) => {
    // eslint-disable-next-line
    const address = place?.formatted_address,
      addressArray = place?.address_components,
      // addressLength = place?.address_components?.length,
      city = place?.formatted_address.split(",")[1],
      state = this.getState(addressArray),
      // state = addressArray[addressLength - 1].long_name,
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    this.setState({
      address_line_1: address ? address : "",
      city: city ? city : "",
      State: state ? state : "",
      markerLat: latValue,
      markerLng: lngValue,
      mapLat: latValue,
      mapLng: lngValue,
    });
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray?.length; i++) {
      // eslint-disable-next-line
      if (addressArray[i].types[0] && "locality" == addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getState = (addressArray) => {
    let State = "";
    const addressLength = addressArray?.length;
    if (
      addressArray[addressLength - 5].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 5].long_name;
      return State;
    } else if (
      addressArray[addressLength - 4].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 4].long_name;
      return State;
    } else if (
      addressArray[addressLength - 3].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 3].long_name;
      return State;
    } else if (
      addressArray[addressLength - 2].types[0] === "administrative_area_level_1"
    ) {
      State = addressArray[addressLength - 2].long_name;
      return State;
    } else {
      State = addressArray[addressLength - 1].long_name;
      return State;
    }
  };
  // Abdul Hai Code End

  // Abdul Wahab Code Continues
  monTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          mon: {
            ...this.state.working_hours.mon,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  tueTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          tue: {
            ...this.state.working_hours.tue,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  wedTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          wed: {
            ...this.state.working_hours.wed,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  thurTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          thu: {
            ...this.state.working_hours.thu,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  friTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          fri: {
            ...this.state.working_hours.fri,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  satTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          sat: {
            ...this.state.working_hours.sat,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  sunTimeUpdate = (typee, e) => {
    var b;
    typee === "checkbox"
      ? (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            [e.target.name]: e.target.checked,
            error: "",
          },
        })
      : (b = {
          ...this.state.working_hours,
          sun: {
            ...this.state.working_hours.sun,
            time: e,
            error: "",
          },
        });
    this.setState({ working_hours: b });
  };

  Service = () => {
    if (
      this.state.service_name !== "" &&
      this.state.service_price !== "" &&
      this.state.service_time !== "" &&
      this.state.service_deposit &&
      this.state.service_description !== ""
    ) {
      const random = Math.floor(100000 + Math.random() * 900000);
      const newService = {
        serviceid: random,
        service_name: this.state.service_name,
        service_price: this.state.service_price,
        service_deposit: this.state.service_deposit,
        service_time: parseInt(this.state.service_time),
        service_description: this.state.service_description,
      };
      this.state.services.push(newService);
      this.setState({
        service_name: "",
        service_price: "",
        service_time: "",
        service_deposit: "",
        service_description: "",
      });
    }
  };

  onUpdateName = (event) => {
    const id = event.target.id.replace("service_name", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id) {
        array = this.state.services;
        array[index].service_name = event.target.value;
        this.setState({
          services: array,
        });
      }
    });
  };

  onUpdatePrice = (event) => {
    const id1 = event.target.id.replace("service_price", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        if (
          parseInt(array[index].service_deposit) > parseInt(event.target.value)
        ) {
          message.error("Service price cannot be less than deposit fee");
        } else {
          array[index].service_price = event.target.value.replace(
            /[^0-9]/gi,
            ""
          );
          this.setState({
            services: array,
          });
        }
      }
    });
  };

  onUpdatetime = (event) => {
    const id1 = event.target.id.replace("service_time", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        const value = Math.max(
          1,
          Number(event.target.value.replace(/[^0-9]/gi, ""))
        );
        array[index].service_time = value;
        this.setState({
          services: array,
        });
      }
    });
  };
  onUpdatedeposit = (event) => {
    const id1 = event.target.id.replace("service_deposit", "");
    var array;
    // eslint-disable-next-line
    this.state.services.map((service, index) => {
      // eslint-disable-next-line
      if (service.serviceid == id1) {
        array = this.state.services;
        if (
          parseInt(array[index].service_price) < parseInt(event.target.value)
        ) {
          message.error("Deposit fee cannot be greator than service price");
        } else {
          const value = Math.max(
            0,
            Number(event.target.value.replace(/[^0-9]/gi, ""))
          );
          array[index].service_deposit = value;
          this.setState({
            services: array,
          });
        }
      }
    });
  };

  onUpdateDescription = (event) => {
    const id1 = event.target.id.replace("service_description", "");
    var array;
    this.state.services.map((service, index) => {
      if (service.serviceid == id1) {
        array = this.state.services;
        array[index].service_description = event.target.value;
        this.setState({
          services: array,
        });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      openModel: false,
    });
  };

  BusinessSignup1 = () => {
    this.setState({
      openModel: true,
      filteredServices: [],
    });
    this.state.services.map((service) => {
      return this.state.filteredServices.push(service);
    });
    const id = this.props.location.pathname.slice(11);
    const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/update_vendor_details/${id}`;
    axios
      .put(URL, {
        business_name: this.state.business_name,
        website: this.state.website,
        city: this.state.city,
        State: this.state.State,
        address_line_1: this.state.address_line_1,
        wifi: this.state.WiFi,
        instant_booking: this.state.Book,
        paypal_email: this.state.paypalEmail,
        facebook_link: this.state.Facebook,
        instagram_link: this.state.Instagram,
        payment_type: this.state.payment_type,
        services: this.state.filteredServices,
        working_hours: this.state.working_hours,
        email: this.state.email,
        password: this.state.password,
        contact: this.state.contact,
        lat: this.state.mapLat,
        lng: this.state.mapLng,
        trevel_to_me: this.state.trevel_to_me,
      })
      .then((res) => {
        if (res.data.success) {
          // message.success("Vendor updated successfully!");
          this.sendImages(id);
          this.setState({
            openModel: false,
          });
        } else {
          message.error(res.data.message);
          this.setState({
            openModel: false,
          });
        }
      });
  };
  sendImages = (id) => {
    this.setState({
      openModel: true,
    });
    let formData = new FormData();
    this.state.Newimages.map((img) => {
      formData.append("images", img);
      return <></>;
    });
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/vendor/update_images/` + id,
        formData
      )
      .then((response) => {
        if (response.data.success === true) {
          // this.setState({
          //   openModel: false,
          // });
        } else if (response.data.success === false) {
          this.setState({
            openModel: false,
          });
          // message.error(response.data.error);
        }
        message.success("Vendor Updated Successfully!");
      })
      .catch(function (error) {
        message.error("error");
        this.setState({
          openModel: false,
        });
      });
  };

  render() {
    const removeService = (id) => {
      const serviceArr = [...this.state.services];
      let myArr = serviceArr.filter((item) => {
        return item.serviceid !== id;
      });
      this.setState({ services: myArr });
    };
    const RenderMap = this.state.services.map((servicemap) => {
      return (
        <RenderMapComp
          servicemap={servicemap}
          onUpdateName={this.onUpdateName}
          onUpdatePrice={this.onUpdatePrice}
          onUpdatetime={this.onUpdatetime}
          onUpdatedeposit={this.onUpdatedeposit}
          onUpdateDescription={this.onUpdateDescription}
          removeService={removeService}
        />
      );
    });

    return (
      <>
        <BlackNavbar />
        <div className=" col-11 mxAuto pt-3">
          <img src={featuredBg} alt="img" className="businessimg"></img>
        </div>
        <EditBussinessForm
          images={this.state.images}
          onHandleChange={this.onHandleChange}
          onNumberChange={this.onNumberChange}
          RenderMap={RenderMap}
          first_name={this.state.first_name}
          payment_type={this.state.payment_type}
          last_name={this.state.last_name}
          email={this.state.email}
          paypalEmail={this.state.paypalEmail}
          contact={this.state.contact}
          password={this.state.password}
          business_name={this.state.business_name}
          website={this.state.website}
          address_line_1={this.state.address_line_1}
          city={this.state.city}
          State={this.state.State}
          onPlaceChange={this.onPlaceChange}
          onMarkerDragEnd={this.onMarkerDragEnd}
          addressChange={this.addressChange}
          google={this.props.google}
          working_hours_close={this.working_hours_close}
          working_hours={this.state.working_hours}
          working_hours_time={this.working_hours_time}
          // monTimeUpdate={this.monTimeUpdate}
          // monTime={this.state.working_hours.mon.time}
          // monTimeClose={this.state.working_hours.mon.closed}
          // tueTimeUpdate={this.tueTimeUpdate}
          // tueTime={this.state.working_hours.tue.time}
          // tueTimeClose={this.state.working_hours.tue.closed}
          // wedTimeUpdate={this.wedTimeUpdate}
          // wedTime={this.state.working_hours.wed.time}
          // wedTimeClose={this.state.working_hours.wed.closed}
          // thurTimeUpdate={this.thurTimeUpdate}
          // thurTime={this.state.working_hours.thu.time}
          // thurTimeClose={this.state.working_hours.thu.closed}
          // friTimeUpdate={this.friTimeUpdate}
          // friTime={this.state.working_hours.fri.time}
          // friTimeClose={this.state.working_hours.fri.closed}
          // satTimeUpdate={this.satTimeUpdate}
          // satTime={this.state.working_hours.sat.time}
          // satTimeClose={this.state.working_hours.sat.closed}
          // sunTimeUpdate={this.sunTimeUpdate}
          // sunTime={this.state.working_hours.sun.time}
          // sunTimeClose={this.state.working_hours.sun.closed}
          service_name={this.state.service_name}
          service_price={this.state.service_price}
          service_time={this.state.service_time}
          service_deposit={this.state.service_deposit}
          error={this.state.error}
          openModel={this.state.openModel}
          loading={this.state.loading}
          book={this.state.Book}
          WiFi={this.state.WiFi}
          trevel_to_me={this.state.trevel_to_me}
          Service={this.Service}
          imagesSet={this.imagesSet}
          onBookChange={this.onBookChange}
          onWiFiChange={this.onWiFiChange}
          onTravelChange={this.onTravelChange}
          verification={this.verification}
          validation={this.BusinessSignup1}
          id={this.props.location.pathname.slice(11)}
          onHandleImageChange={this.onHandleImageChange}
        />
      </>
    );
  }
}

export default EditYourBusiness;
