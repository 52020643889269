import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";

import { NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import LegalName from "../../API/PersonalInfo/UserPersonalInfo/LegalName";
import Gender from "../../API/PersonalInfo/UserPersonalInfo/Gender";
import DateofBirth from "../../API/PersonalInfo/UserPersonalInfo/DateofBirth";
import Address from "../../API/PersonalInfo/UserPersonalInfo/Address";
import Contact from "../../API/PersonalInfo/UserPersonalInfo/Contact";
import { Spin } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import CountriesList from "../data/CountiesList";

const UserPersonalInfo = () => {
  const initialstate = {
    first_name: "",
    last_name: "",
    gender: "",
    country_code: "",
    date_of_birth: "",
    country: "",
    street_address: "",
    apt: "",
    city: "",
    State: "",
    zip_code: "",
    update: false,
  };
  const [contact, setContact] = useState();
  const [FormData, setFormData] = useState(initialstate);
  const {
    first_name,
    last_name,
    gender,
    country_code,
    country,
    street_address,
    apt,
    city,
    State,
    zip_code,
    date_of_birth,
    update,
  } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const getData = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/user/get_user/` +
      localStorage.getItem("userId");
    axios.get(link).then((res) => {
      if (res.data.success) {
        setFormData({
          ...FormData,
          first_name: res.data.data.first_name,
          last_name: res.data.data.last_name,
          gender: res.data.data.gender,
          country_code: res.data.data.country_code,
          date_of_birth: res.data.data.date_of_birth,
          country: res.data.data.personal_address.country,
          street_address: res.data.data.personal_address.street_address,
          apt: res.data.data.personal_address.apt,
          city: res.data.data.personal_address.city,
          State: res.data.data.personal_address.State,
          zip_code: res.data.data.personal_address.zip_code,
          update: true,
        });
        setContact(res.data.data.contact);
      }
    });
  };

  useEffect(() => {
    getData();
    $("#first_name").prop("disabled", true);
    $("#last_name").prop("disabled", true);
    $("#gender").prop("disabled", true);
    $("#date_of_birth").prop("disabled", true);
    $("#country_code").prop("disabled", true);
    $("#contact").prop("disabled", true);
    $("#street_address").prop("disabled", true);
    $("#country").prop("disabled", true);
    $("#apt").prop("disabled", true);
    $("#zip_code").prop("disabled", true);
    $("#State").prop("disabled", true);
    $("#city").prop("disabled", true);
    // eslint-disable-next-line
  }, []);

  const editName = () => {
    $("#name").removeClass("d-none");
    $("#editName").addClass("d-none");
    $("#first_name").prop("disabled", false);
    $("#last_name").prop("disabled", false);
  };
  const cancelName = () => {
    $("#name").addClass("d-none");
    $("#editName").removeClass("d-none");
    $("#first_name").prop("disabled", true);
    $("#last_name").prop("disabled", true);
    getData();
  };

  const editGender = () => {
    $("#editgender").addClass("d-none");
    $("#savegender").removeClass("d-none");
    $("#gender").prop("disabled", false);
  };
  const cancelGender = () => {
    $("#editgender").removeClass("d-none");
    $("#savegender").addClass("d-none");
    $("#gender").prop("disabled", true);
    getData();
  };

  const editDob = () => {
    $("#dob").removeClass("d-none");
    $("#editDob").addClass("d-none");
    $("#date_of_birth").prop("disabled", false);
  };
  const cancelDob = () => {
    $("#dob").addClass("d-none");
    $("#editDob").removeClass("d-none");
    $("#date_of_birth").prop("disabled", true);
    getData();
  };
  const editPhone = () => {
    $("#phone").removeClass("d-none");
    $("#editPhone").addClass("d-none");
    $("#country_code").prop("disabled", false);
    $("#contact").prop("disabled", false);
  };
  const cancelPhone = () => {
    $("#phone").addClass("d-none");
    $("#editPhone").removeClass("d-none");
    $("#country_code").prop("disabled", true);
    $("#contact").prop("disabled", true);
    getData();
  };

  const editAddress = () => {
    $("#address").removeClass("d-none");
    $("#editAddresss").addClass("d-none");
    $("#street_address").prop("disabled", false);
    $("#apt").prop("disabled", false);
    $("#zip_code").prop("disabled", false);
    $("#State").prop("disabled", false);
    $("#city").prop("disabled", false);
    $("#country").prop("disabled", false);
  };
  const cancelAddress = () => {
    $("#address").addClass("d-none");
    $("#editAddresss").removeClass("d-none");
    $("#street_address").prop("disabled", true);
    $("#apt").prop("disabled", true);
    $("#zip_code").prop("disabled", true);
    $("#State").prop("disabled", true);
    $("#city").prop("disabled", true);
    $("#country").prop("disabled", true);
    getData();
  };
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-12 Orange border196 pt-5">
          <div className="col-11 mxAuto maxWidth">
            <p className="White pt-5  font17">
              <NavLink to="/account" className="White">
                Account
              </NavLink>{" "}
              &gt; Personal Info
            </p>
            <h1 className="White  pb-3">Personal Info</h1>
          </div>
        </div>

        <div className="col-11 mxAuto maxWidth">
          <div className="row pt-5">
            <div className=" col-md-6 col-11 ">
              <div className="row">
                <h4 className="mr-auto">Legal Name</h4>
                <button
                  type="button"
                  className="btn orangecolor ml-auto"
                  id="editName"
                  onClick={editName}
                >
                  Edit
                </button>
              </div>

              <form className="row" id="namee">
                <input
                  type="text"
                  className="col-lg-6 col-md-9  col-12 rounded-0 fillcolor p-2 bordercolor mt-1"
                  id="first_name"
                  placeholder="First name"
                  name="first_name"
                  value={first_name}
                  onChange={onHandleChange}
                ></input>
                <input
                  type="text"
                  className="col-lg-6 col-md-9  col-12 rounded-0 fillcolor p-2 bordercolor mt-1"
                  id="last_name"
                  name="last_name"
                  value={last_name}
                  onChange={onHandleChange}
                  placeholder="Last name"
                ></input>
              </form>
              <div className="col-12 pt-3 d-none" id="name">
                <button
                  type="button"
                  id="name"
                  className="btn Orange White"
                  onClick={() => LegalName(first_name, last_name)}
                >
                  Save{" "}
                </button>
                <button
                  type="button"
                  className="btn float-right orangecolor"
                  onClick={cancelName}
                >
                  Cancel
                </button>
              </div>

              <div className="overflow-hidden pt-2">
                <div className="row">
                  <h4 className="mr-auto ">Gender</h4>
                  <button
                    type="button"
                    className="btn orangecolor ml-auto"
                    id="editGender"
                    onClick={editGender}
                  >
                    Edit
                  </button>
                </div>
                <select
                  className="col-12 p-2 bordercolor rounded-0 fillcolor"
                  name="gender"
                  id="gender"
                  value={gender}
                  onChange={onHandleChange}
                >
                  <option>Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="col-12 pt-3 d-none" id="savegender">
                <button
                  type="button"
                  className="btn Orange White"
                  onClick={() => Gender(gender)}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn float-right orangecolor"
                  onClick={cancelGender}
                >
                  Cancel
                </button>
              </div>

              <div className="row pt-2">
                <h4 className="mr-auto">Date of Birth</h4>
                <button
                  type="button"
                  className="btn orangecolor ml-auto"
                  id="editDob"
                  onClick={editDob}
                >
                  Edit
                </button>
              </div>
              <div
                htmlFor="date_of_birth"
                className="overflow-hidden row col-12"
              >
                <input
                  type="date"
                  className="col-12 bordercolor p-2 rounded-0 fillcolor"
                  max={today}
                  id="date_of_birth"
                  name="date_of_birth"
                  value={FormData?.date_of_birth?.slice(0, 10)}
                  onChange={onHandleChange}
                />
              </div>
              <div className="col-12 pt-3 d-none" id="dob">
                <button
                  type="button"
                  className="btn Orange White"
                  onClick={() => DateofBirth(date_of_birth)}
                >
                  {" "}
                  Save
                </button>
                <button
                  type="button"
                  className="btn float-right orangecolor"
                  onClick={cancelDob}
                >
                  Cancel
                </button>
              </div>

              <div className="row">
                <h4 className="mr-auto pt-2">Phone Number</h4>
                <button
                  type="button"
                  className="btn orangecolor ml-auto"
                  id="editPhone"
                  onClick={editPhone}
                >
                  Edit
                </button>
              </div>
              <div id="contact">
                <ReactPhoneInput
                  inputExtraProps={{
                    name: "contact",
                    required: true,
                    autoFocus: true,
                  }}
                  defaultCountry={"sg"}
                  value={contact ? contact : ""}
                  onChange={(text) => setContact(text)}
                />
              </div>
              <div className="col-12 pt-3 d-none" id="phone">
                <button
                  type="button"
                  className="btn Orange White"
                  onClick={() => Contact(contact, country_code)}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn float-right orangecolor"
                  onClick={cancelPhone}
                >
                  Cancel
                </button>
              </div>

              <div className="row">
                <h4 className="mr-auto pt-2">Address</h4>
                <button
                  type="button"
                  className="btn orangecolor ml-auto"
                  id="editAddresss"
                  onClick={editAddress}
                >
                  Edit
                </button>
              </div>
              <form>
                <div className="form-row">
                  <div className="col-md-6 col-12">
                    <label className="font17">Country</label>
                    <select
                      id="country"
                      className="form-control rounded-0 fillcolor"
                      name="country"
                      value={country}
                      onChange={onHandleChange}
                    >
                      <option>Select Country</option>
                      {CountriesList.map((index, i) => {
                        return (
                          <option value={index.name} key={i}>
                            {index.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <label className="font17">Street Address</label>
                  <input
                    type="text"
                    id="street_address"
                    className="col-12 p-2 bordercolor rounded-0 fillcolor"
                    placeholder="Ex. 123 main street"
                    name="street_address"
                    value={street_address}
                    onChange={onHandleChange}
                  ></input>
                </div>
                <label className="font17">Apt, Suite (optional)</label>
                <input
                  type="text"
                  id="apt"
                  className="col-12 bordercolor p-2 rounded-0 fillcolor"
                  placeholder="Ex. #3 Apt"
                  name="apt"
                  value={apt}
                  onChange={onHandleChange}
                ></input>
                <div className="form-row">
                  <div className="col-md-6 col-12">
                    <label className="font17">City</label>
                    <input
                      type="text"
                      id="city"
                      className="form-control rounded-0 fillcolor"
                      placeholder="City"
                      name="city"
                      value={city}
                      onChange={onHandleChange}
                    ></input>
                  </div>
                  <div className="col-md-6 col-12">
                    <label className="font17">State</label>
                    <input
                      type="text"
                      id="State"
                      className="form-control rounded-0 fillcolor"
                      placeholder="State"
                      name="State"
                      value={State}
                      onChange={onHandleChange}
                    ></input>
                  </div>
                </div>
                <div className="form-row">
                  <div className=" col-md-6">
                    <label htmlFor="zip_code" className="font17">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      id="zip_code"
                      className="form-control rounded-0 fillcolor"
                      placeholder="Ex. 12230"
                      name="zip_code"
                      value={zip_code}
                      onChange={onHandleChange}
                    ></input>
                  </div>
                </div>
                <div className="col-12 pt-3 d-none" id="address">
                  <button
                    type="button"
                    className="btn Orange White"
                    onClick={() =>
                      Address(
                        country,
                        street_address,
                        apt,
                        city,
                        State,
                        zip_code
                      )
                    }
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn float-right orangecolor"
                    onClick={cancelAddress}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default UserPersonalInfo;
