import { Progress, Radio } from "antd";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import BlackNavbar from "../../SubComponents/BlackNavbar";
const SelectReason = () => {
  const initialstate = {
    value: 1,
  };

  const [formData, setformData] = useState(initialstate);
  const { value } = formData;

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setformData({
      ...formData,
      value: e.target.value,
    });
  };
  let id = localStorage.getItem("userId");
  return (
    <div>
      <BlackNavbar />
      <div className="maxWidth mxAuto">
        <p className="col-lg-4 col-md-9 mxAuto pt-3">
          <span className="orangecolor ">1. Select Reason &gt;</span>
          <span className="Black pl-3">2. Confirm &gt;</span>
          <span> 3. Done </span>
        </p>

        <div className="col-9 mxAuto">
          <Progress percent={30} status="active" showInfo={false} />
        </div>
        <div className="col-lg-4 col-md-9 mxAuto pt-5 rightRadio">
          <div className="row">
            <div className="col-12">
              <h6 className="font39">What prompted you to deactivate?</h6>
            </div>
            <div className="col-12">
              <Radio.Group onChange={onChange} value={value}>
                <Radio className="col-12 py-3" value={1}>
                  I have safety or privacy concerns.
                </Radio>
                <Radio className="col-12 py-3" value={2}>
                  Moving to another Platform.
                </Radio>
                <Radio className="col-12 py-3" value={4}>
                  other...
                  {value === 4 ? (
                    <input
                      type="text"
                      className="col-11 p-1 bordercolor fillcolor Radius_7"
                      style={{ width: "68%", marginLeft: 10 }}
                      placeholder="Why are you leaving ?"
                    ></input>
                  ) : null}
                </Radio>
              </Radio.Group>
            </div>
            <div className="col-12">
              <NavLink to={"/deactivate/" + id}>
                {" "}
                <button className="btn Orange White float-right mt-3 mb-5">
                  Continue
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectReason;
