import React, { Suspense, lazy } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Redirect, Route, Switch } from "react-router-dom";
// import Home from './components/Home'
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsandCondition from "./components/TermsandCondition";
import Covid19 from "./components/Covid19";
import MapViewListing from "./components/MapViewListing";
import BusinessListing from "./components/BusinessListing";
import Signup from "./components/Signup";
import Careers from "./components/Careers";
import LandingScreenforCheck from "./components/LandingScreenforCheck";
import Login from "./components/Login/Login";
import CustomerLogin from "./components/Login/CustomerLogin";
import VendorLogin from "./components/Login/VendorLogin";
import SaloonDetail from "./components/SaloonDetail";
import UserForgetPassword from "./components/UserForgetPassword";
import VendorForgetPassword from "./components/VendorForgetPassword";
import Blogs from "./components/Blogs";
import HowToList from "./components/HowToList";
import StatesListing from "./components/StatesListing";
import BuisnessListingwithoutMap from "./components/BuisnessListingwithoutMap";
import Footer from "./components/SubComponents/Footer";
import CodeValidatiion from "./components/CodeValidatiion";
import BlogDetails from "./components/BlogDetails";
import Success from "./components/Success";
import Fail from "./components/Vendor/Fail";
import ListYoutBusiness from "./components/ListYourBusiness";
import { Spin } from "antd";
import ResetPasswordUser from "./components/ResetPasswordUser";
import ResetPasswordVendor from "./components/ResetPasswordVendor";
import ConformDeactivate from "./components/User/ConformDeactivate";
const App = () => {
  const Home = lazy(() => import("./components/Home"));

  return (
    <div
      style={{
        minHeight: "100vh",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <>
        <Suspense fallback={<Spin size="large" />}>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </Suspense>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/customerlogin" component={CustomerLogin} />
          <Route exact path="/vendorlogin" component={VendorLogin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/ValidateYourSelf" component={CodeValidatiion} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/ContactUs" component={ContactUs} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsandCondition} />
          <Route exact path="/covid19" component={Covid19} />
          <Route exact path="/Business" component={ListYoutBusiness} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/howto" component={HowToList} />
          <Route exact path="/MapViewListing" component={MapViewListing} />
          <Route exact path="/vender/:state" component={StatesListing} />
          <Route exact path="/businesslisting" component={BusinessListing} />
          <Route path="/deactivate_account" component={ConformDeactivate} />
          <Route
            exact
            path="/Saloonlist/:state"
            component={BuisnessListingwithoutMap}
          />
          <Route exact path="/saloonDetail/:_id" component={SaloonDetail} />
          <Route exact path="/careers" component={Careers} />
          <Route
            exact
            path="/userforgetPassword"
            component={UserForgetPassword}
          />
          <Route
            exact
            path="/userforgetPassword"
            component={UserForgetPassword}
          />
          <Route
            exact
            path="/user/forget_password/:_id"
            component={ResetPasswordUser}
          />
          <Route
            exact
            path="/vendor/forget_password/:_id"
            component={ResetPasswordVendor}
          />
          <Route
            exact
            path="/vendorforgetPassword"
            component={VendorForgetPassword}
          />
          <Route exact path="/blog/:_id" component={BlogDetails} />
          <Route exact path="/stripe-account-success" component={Success} />
          <Route exact path="/stripe-account-fail" component={Fail} />
          <LandingScreenforCheck />
          <Redirect to="/" />
        </Switch>
      </>
      <Footer />
    </div>
  );
};

export default App;
