import React, { useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import featuredBg from "../../assests/featuredBg.jpg";
import { NavLink } from "react-router-dom";
// import BusinessLogin from "../../API/Login/BusinessLogin";
import { message, Modal } from "antd";
import Confirmed from "../../assests/confirmed.svg";
import axios from "axios";
const VendorLogin = () => {
  const initialstate = {
    email: "",
    password: "",
    openSuccessModal: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { email, password, openSuccessModal } = FormData;

  const validation = () => {
    if (email !== "") {
      if (email.includes("@") && email.includes(".")) {
        if (password !== "") {
          // BusinessLogin(email, password);
          const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/login`;
          axios
            .post(URL, {
              email: email,
              password: password,
            })
            .then((res) => {
              if (res.data.success) {
                setFormData({
                  ...FormData,
                  openSuccessModal: true,
                });
                localStorage.setItem("type", "vendor");
                localStorage.setItem("venderId", res.data.data._id);
                localStorage.setItem(
                  "VenderPaypal",
                  res.data.data.paypal_email
                );
                // message.success("Welcome !");
              } else {
                message.error(res.data.message);
              }
            });
        } else if (password === "") {
          setFormData({
            ...FormData,
            error: "Password is Required",
          });
          message.error("Password is Required");
        }
      } else {
        setFormData({
          ...FormData,
          error: "Email is not valid",
        });
        message.error("Email is not valid");
      }
    } else if (email === "") {
      setFormData({
        ...FormData,
        error: "Email is Required",
      });
      message.error("Email is Required");
    }
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  const goToHome = () => {
    window.location = "/account";
  };
  const Enterr = (e) => {
    if (e.key === "Enter") {
      validation();
    }
  };

  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1  bg-image img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${featuredBg})` }}
      >
        <div className=" col-lg-5 col-md-8 col-11 mxAuto bg-white px-0 shadow mt-5">
          <div className="bgGrey ">
            <h1 className="text-center m-0">Vendor Login</h1>
          </div>
          <form>
            <div className="col-11 mxAuto ">
              <div>
                <input
                  type="email"
                  className="col-12 bordercolor loginemail fillcolor pl-4 rounded-0"
                  placeholder="Username or Email Address"
                  required
                  name="email"
                  value={email}
                  onChange={onHandleChange}
                />
              </div>

              <div className="pt-2">
                <input
                  type="password"
                  className="col-12 bordercolor loginpassword fillcolor mb-2 pl-4 rounded-0"
                  placeholder="Password"
                  required
                  name="password"
                  value={password}
                  onChange={onHandleChange}
                  onKeyDown={Enterr}
                />
              </div>
              <div className="pt-2">
                <NavLink to="/vendorforgetPassword" className="Black col-12">
                  Forget Password?
                </NavLink>
              </div>
            </div>
            <div className="form-group col-11 mxAuto pt-2 pb-5">
              <button
                type="button"
                className="signupbtn mt-3 rounded-0"
                onClick={validation}
              >
                Login
              </button>
            </div>
          </form>
        </div>
        <Modal
          className="OptPopUp"
          open={openSuccessModal}
          footer={null}
          closable={false}
        >
          {" "}
          {/* //width="80%" */}
          <img
            src={Confirmed}
            alt="img"
            className="mx-auto d-block mb-3"
            style={{ width: "70px" }}
          />
          <p className="text-center font14 mb-4">
            Congratulation! you are Logged In
          </p>
          <button
            className="btn Orange Radius_14 White mxAuto d-block"
            onClick={goToHome}
          >
            Continue
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default VendorLogin;
