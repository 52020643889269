import React from "react";
import dateFormat from "dateformat";
import { Table } from "antd";

export default function ReportsComponent(props) {
  const data = props.data;
  function onChange(pagination, filters, sorter, extra) {}

  const columns = [
    {
      title: "Customer Name",
      render: (value) => value.first_name + " " + value.last_name,
      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
      },
    },
    {
      title: "Report Date",
      render: (render) => dateFormat(render.date_of_report, "mm/dd/yyyy"),
      sorter: {
        compare: (a, b) => a.date_of_report - b.date_of_report,
      },
    },
    {
      title: "remarks",
      render: (render) => render.remarks,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        className="table-responsive"
      />
    </>
  );
}
