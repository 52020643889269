import axios from "axios";
import confirmed from "../../assests/confirmed.svg";
import { NavLink } from "react-router-dom";
const Report = (remarks, vendor_id, user_id) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/report/` +
    vendor_id +
    `/` +
    user_id;
  axios
    .post(URL, {
      remarks: remarks,
    })
    .then((res) => {
      if (res.data.success) {
        <div
          className="modal fade "
          id="report"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content ">
              <img
                src={confirmed}
                alt=""
                className="w-25 mxAuto pt-3 pb-0"
              ></img>
              <div className="modal-body ">
                <p className="  text-center col-8 mxAuto font17 ">
                  Your Report Has been Submitted Successfully. Our team will
                  inquire and take necessary actions.
                </p>
              </div>
              <NavLink
                to="/userbookings"
                className="col-12 justify-content-center pb-5"
              >
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn Orange White p-2 w-50 d-block mxAuto Radius_10"
                >
                  OK
                </button>
              </NavLink>
            </div>
          </div>
        </div>;
      } else {
        alert(res.data.message);
      }
    });
};

export default Report;
