import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const Contact = (contact, country_code) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/contact/` +
    localStorage.getItem("venderId");
  axios
    .put(URL, {
      contact: contact,
      country_code: country_code,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Contact Updated Successfully");
        $("#phone").addClass("d-none");
        $("#editPhone").removeClass("d-none");
        $("#country_code").prop("disabled", true);
        $("#contact").prop("disabled", true);
      } else {
        message.error(res.data.message);
      }
    });
};

export default Contact;
