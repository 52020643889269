import { message } from "antd";
import axios from "axios";
import $ from "jquery";
const UserLoginSecurity = (current_password, password, confirmPwd) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/user/update_password/` +
    localStorage.getItem("userId");
  axios
    .put(URL, {
      current_password: current_password,
      password: password,
      confirmPwd: confirmPwd,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Password Updated Successfully");
        $("#pwd").addClass("d-none");
        $("#updatePwd").removeClass("d-none");
        $("#confirmPwd").prop("disabled", true);
        $("#current_password").prop("disabled", true);
        $("#password").prop("disabled", true);
        window.location = "/security/" + localStorage.getItem("userId");
      } else {
        message.error(res.data.message);
      }
    });
};

export default UserLoginSecurity;
