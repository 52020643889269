import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";

const BlogDetails = () => {
  const { _id } = useParams();
  const initialstate = {
    blog: "",
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { blog, update } = FormData;

  useEffect(() => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/admin/get_blog/` + _id;
    axios.get(link).then((res) => {
      if (res.data.success) {
        setFormData({
          ...FormData,
          blog: res.data.data,
          update: true,
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />

        <div
          className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
          style={{ backgroundImage: `url(${headerimage})` }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-11 mxAuto px-0">
                <div className="mask p-md-5 my-auto mxAuto ">
                  <div className="col-12  mxAuto mb-5 px-auto">
                    <h1 className="font58 White pt-5">Blog</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-11 mx-auto mt-5 maxWidth">
          <div className="col-md-10 col-12 ">
            <h1>{blog.title}</h1>
            <img
              src={blog.images[0]}
              alt=""
              style={{ minWidth: "320px", maxWidth: "440px" }}
            ></img>
            <div dangerouslySetInnerHTML={{ __html: blog.description }} />
          </div>
        </div>
      </>
    );
  }
};

export default BlogDetails;
