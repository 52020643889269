import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import $ from "jquery";
import crossicon from "../assests/crossicon.png";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { Switch, Spin } from "antd";
import LiveLocation from "./LiveLocation";
import SaloonMapView from "./SubComponents/SaloonMapView";

const MapViewListing = (props) => {
  const initialstate = {
    detail: "",
    allVendor: [],
    services: "",
    business_name: "",
    address: "",
    typeofVendor: "",
    instant: false,
    wifi: false,
    update: false,
    travel: false,
    updated: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { allVendor, instant, wifi, travel, typeofVendor, updated, update } =
    FormData;

  // SALOON DATA
  const Saloons = () => {
    const link = `${process.env.REACT_APP_BASE_URL}/api/vendor/search`;
    axios
      .post(link, {
        business_name: props.location.state.business_name,
        address: props.location.state.address,
      })
      .then((response) => {
        console.log(response);
        if (response.data.success === true) {
          setFormData({
            ...FormData,
            allVendor: response.data.data,
            update: true,
            updated: true,
          });
        } else if (response.data.success === false) {
          setFormData({
            ...FormData,
            allVendor: [],
            update: true,
            updated: true,
          });
        }
      })

      .catch(function (error) {});
  };
  useEffect(() => {
    Saloons();
    // eslint-disable-next-line
  }, []);

  const classfunction = () => {
    $(".data").addClass("d-none");
  };

  // function onFullChange(checked) {
  //     if (checked === true && Half === true) {
  //         setFormData({
  //             ...FormData,
  //             typeofVendor: "",
  //             Full: true
  //         })
  //     }
  //     else if (checked === true && Half === false) {
  //         setFormData({
  //             ...FormData,
  //             typeofVendor: "full",
  //             Full: true
  //         })
  //     }
  //     else if (checked === false && Half === true) {
  //         setFormData({
  //             ...FormData,
  //             typeofVendor: "deposit",
  //             Full: false
  //         })
  //     }
  //     else if (checked === false && Half === false) {
  //         setFormData({
  //             ...FormData,
  //             typeofVendor: "",
  //             Full: false
  //         })
  //     }
  // }

  function onDepositChange(checked) {
    if (checked === true) {
      setFormData({
        ...FormData,
        typeofVendor: "deposit",
      });
    }
    if (checked === false) {
      setFormData({
        ...FormData,
        typeofVendor: "",
      });
    }
  }

  function onInstantChange(checked) {
    setFormData({
      ...FormData,
      instant: checked,
    });
  }

  function onWifiChange(checked) {
    setFormData({
      ...FormData,
      wifi: checked,
    });
  }

  function onTravelChange(checked) {
    setFormData({
      ...FormData,
      travel: checked,
    });
  }
  const showMap = (e) => {
    var isExpanded = $(e.target).hasClass("showMap");
    if (isExpanded) {
      $(".maps").addClass("d-none");
      $(".mapButton").addClass("hideMap");
      $(".mapButton").removeClass("showMap");
      $(".mapButton").html("Show Map");
    } else if (!isExpanded) {
      $(".maps").removeClass("d-none");
      $(".mapButton").addClass("showMap");
      $(".mapButton").removeClass("hideMap");
      $(".mapButton").html("Hide Map");
    }
  };

  // eslint-disable-next-line
  const RenderSaloonMapViewData = allVendor.map((saloonmapview) => {
    // eslint-disable-next-line
    if (wifi == false || saloonmapview.wifi == wifi) {
      // eslint-disable-next-line
      if (instant == false || saloonmapview.instant_booking == instant) {
        // eslint-disable-next-line
        if (
          typeofVendor !== "deposit" ||
          // eslint-disable-next-line
          saloonmapview.payment_type == typeofVendor
        ) {
          // eslint-disable-next-line
          if (travel == false || saloonmapview.trevel_to_me == travel) {
            return (
              <>
                {
                  <SaloonMapView
                    key={saloonmapview._id}
                    saloonmapview={saloonmapview}
                  ></SaloonMapView>
                }
              </>
            );
          }
        }
      }
    }
  });

  if (updated !== true) {
    return <Spin size="large" />;
  } else if (updated === true) {
    return (
      <>
        <BlackNavbar />
        <div className="row paddingTop_3">
          <div className="col-lg-6 col-11 mxAuto PadBottom ">
            <div className="row  max-auto">
              <div className="col-6 ">
                <p className="px-0 Bold">Vendor Type Filter</p>
                {/* <div className="row">
                                    <p className=" mr-auto ">Professional</p>
                                    <Switch size="small" onChange={onFullChange} className="ml-auto" />

                                </div> */}
                <div className=" row">
                  <p className="px-0 mr-auto">Deposit Payment</p>
                  <Switch
                    size="small"
                    onChange={onDepositChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Travel To Me</p>
                  <Switch
                    size="small"
                    onChange={onTravelChange}
                    className="w-auto"
                  />
                </div>
              </div>
              {console.log(FormData)}
              <div className="col-6">
                <p className="px-0 Bold">Other Filters</p>
                <div className=" row">
                  <p className="px-0 mr-auto">Instant Booking</p>
                  <Switch
                    size="small"
                    onChange={onInstantChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Wifi</p>
                  <Switch
                    size="small"
                    onChange={onWifiChange}
                    className="w-auto"
                  />
                </div>
              </div>
            </div>

            <hr></hr>
            <div className="row m-0 p-0 mt-3 data mapscroll col-md-12 col-lg-12">
              {updated !== true ? (
                <Spin size="large" />
              ) : allVendor.length < 1 ? (
                <h2 className="text-center">
                  No Professional Available Right Now for your search
                </h2>
              ) : (
                RenderSaloonMapViewData
              )}
            </div>

            <button
              className="btn Orange White d-lg-none mapButton showMap mt-5"
              type="button"
              onClick={showMap}
            >
              Show Map
            </button>
          </div>

          {update === true ? (
            <div className="col-lg-5 col-10 mxAuto crossimg maps d-none d-lg-block">
              <LiveLocation
                store={allVendor}
                wifi={wifi}
                instant={instant}
                travel={travel}
                typeofVendor={typeofVendor}
                stateView="0"
                originLat={
                  allVendor.length !== 0 ? allVendor[0].lat : 40.711391223589956
                }
                originLng={
                  allVendor.length !== 0 ? allVendor[0].lng : -74.00283887121081
                }
              />
              <button className="btn d-none d-lg-block">
                <NavLink
                  to={{
                    pathname: "/businesslisting",
                    state: {
                      allvendor: allVendor,
                      business_name: props.location.state.business_name,
                      address: props.location.state.address,
                    },
                  }}
                >
                  <img
                    src={crossicon}
                    id="1"
                    alt=""
                    className="border-0 "
                    onClick={classfunction}
                  ></img>
                </NavLink>
              </button>
            </div>
          ) : (
            <div className="col-lg-5 col-10 mxAuto crossimg maps d-none d-lg-block">
              <p>Loading Map...</p>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default MapViewListing;
