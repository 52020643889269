import { Progress, Radio } from "antd";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import BlackNavbar from "../../SubComponents/BlackNavbar";
const SelectReason = () => {
  const initialstate = {
    value: 1,
  };

  const [formData, setformData] = useState(initialstate);
  const { value } = formData;

  const onChange = (e) => {
    setformData({
      ...formData,
      value: e.target.value,
    });
  };
  let vendor_id = localStorage.getItem("venderId");
  return (
    <div>
      <BlackNavbar />
      <div className="maxWidth mxAuto">
        <p className="col-lg-4 col-md-9 mxAuto pt-3">
          <span>
            {" "}
            <NavLink to="/selectreason" className="orangecolor ">
              1. Select Reason &gt;
            </NavLink>
          </span>
          <span>
            <NavLink to="/#" className="Black pl-3">
              2. Confirm &gt;
            </NavLink>
          </span>
          <span>
            <NavLink to="/#" className="Black pl-3">
              3. Done
            </NavLink>
          </span>
        </p>

        <div className="col-9 mxAuto">
          <Progress percent={30} status="active" showInfo={false} />
        </div>
        <div className="col-lg-4 col-md-9 mxAuto pt-5 rightRadio">
          <h6 className="font39">What prompted you to deactivate?</h6>

          <Radio.Group onChange={onChange} value={value}>
            <Radio className="col-12 py-3" value={1}>
              I have safety or privacy concerns.
            </Radio>
            <Radio className="col-12 py-3" value={2}>
              I can’t host anymore.
            </Radio>
            <Radio className="col-12 py-3" value={4}>
              other...
              {value === 4 ? (
                <input
                  type="text"
                  className="col-11 p-1 bordercolor fillcolor Radius_7"
                  style={{ width: "68%", marginLeft: 10 }}
                  placeholder="Why are you leaving ?"
                ></input>
              ) : null}
            </Radio>
          </Radio.Group>

          <NavLink to={"/deactivate/" + vendor_id}>
            {" "}
            <button className="btn Orange White float-right mt-3 mb-5">
              Continue
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SelectReason;
