import { message } from "antd";
import axios from "axios";
const Hiring = (name, email, message1) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/user/hiring`;
  axios
    .post(URL, {
      name: name,
      message: message1,
      email: email,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Thank you for Your Time");
        window.location = "/";
      } else {
        message.error(res.data.message);
      }
    });
};
export default Hiring;
