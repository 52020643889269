/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import blacklogo from "../../assests/blacklogo.png";
import userimage from "../../assests/userimage.svg";
import cardimage from "../../assests/CardImg.png";

import { Link, NavLink } from "react-router-dom";
import { message } from "antd";
import { transparentLayerContext } from "../../store/TransparentLayerProvider";
import { useCallback } from "react";
const BlackNavbar = () => {
  const isVendor = localStorage.getItem("type");
  const vendorId = localStorage.getItem("venderId");
  const transparentLayerCtx = useContext(transparentLayerContext);
  const [isOpen, setIsOpen] = useState(false);
  let loggedIn = false;
  if (
    localStorage.getItem("type") === "user" ||
    localStorage.getItem("type") === "vendor"
  ) {
    loggedIn = true;
  }
  const logoutFunction = () => {
    setIsOpen(false);
    loggedIn = false;
    localStorage.clear();
    message.success("Logged Out!");
  };
  let id = localStorage.getItem("userId") || localStorage.getItem("venderId");

  const connectMenuClickHandler = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      transparentLayerCtx.open(() => {
        setIsOpen(false);
        transparentLayerCtx.close();
      });
    } else {
      setIsOpen(false);
      transparentLayerCtx.close();
    }
  });
  return (
    <>
      <div className="col-12 bg-white maxWidth NavBar">
        <div className="container-fluid col-11 mxAuto">
          <nav className="navbar navbar-expand-md bnav px-0">
            <NavLink exact className="navbar-brand " to="/">
              <img className="" src={blacklogo} alt=""></img>
            </NavLink>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src={cardimage} className="navbar-toggler-icon" alt=""></img>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto ">
                <li className="nav-item ">
                  <NavLink className="nav-link Black" to="/">
                    Home <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link Black" to="/aboutus">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link Black" to="/contactus">
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  {isVendor === "vendor" ? (
                    <NavLink
                      className="nav-link Black"
                      to={`/bussiness/${vendorId}`}
                    >
                      Edit Your Business
                    </NavLink>
                  ) : (
                    isVendor !== "user" && (
                      <NavLink className="nav-link Black" to="/business">
                        List Your Business
                      </NavLink>
                    )
                  )}
                </li>

                {loggedIn ? (
                  <>
                    <li className="nav-item m_view">
                      <NavLink className="dropdown-item" to="/account">
                        Account
                      </NavLink>
                    </li>

                    <li className="nav-item m_view">
                      <NavLink className="dropdown-item" to="/careers">
                        Careers
                      </NavLink>
                    </li>

                    <li className="nav-item m_view">
                      <NavLink
                        className="dropdown-item"
                        to={"/notification/" + id}
                      >
                        Notifications
                      </NavLink>
                    </li>

                    <li className="nav-item m_view">
                      <NavLink
                        className="dropdown-item"
                        to="/"
                        onClick={logoutFunction}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item m_view">
                      <NavLink className="dropdown-item" to="/careers">
                        Careers
                      </NavLink>
                    </li>

                    <li className="nav-item m_view">
                      <NavLink className="dropdown-item" to="/login">
                        Login
                      </NavLink>
                    </li>

                    <li className="nav-item m_view">
                      <NavLink className="dropdown-item" to="/signup">
                        Signup
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
              <div
                className="dropdown show rounded D_view"
                style={{ position: "relative", zIndex: 50 }}
              >
                <button
                  className="btn btn-outline-none dropdownbtn"
                  type="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  // onClick={connectMenuClickHandler}
                  onClickCapture={connectMenuClickHandler}
                >
                  <img src={userimage} alt=""></img>
                </button>
                {isOpen && loggedIn ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        display: "block",
                        left: "-7rem",
                      }}
                      className="dropdown-menu dropdownmenu"
                      aria-labelledby="dropdownMenuLink"
                      id="usernavbar"
                    >
                      <NavLink
                        onClick={() => setIsOpen(false)}
                        className="dropdown-item"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderTopRightRadius: "5px",
                        }}
                        to="/account"
                      >
                        Account
                      </NavLink>
                      <NavLink
                        onClick={() => setIsOpen(false)}
                        className="dropdown-item"
                        to="/careers"
                      >
                        Careers
                      </NavLink>
                      <NavLink
                        onClick={() => setIsOpen(false)}
                        className="dropdown-item"
                        to={"/notification/" + id}
                      >
                        Notifications
                      </NavLink>
                      <NavLink
                        className="dropdown-item"
                        style={{ borderRadius: "16px" }}
                        to="/"
                        onClick={logoutFunction}
                      >
                        Logout
                      </NavLink>
                    </div>{" "}
                  </>
                ) : (
                  isOpen &&
                  !loggedIn && (
                    <>
                      <div
                        className="dropdown-menu dropdownmenu "
                        style={{
                          position: "absolute",
                          display: "block",
                          left: "-7rem",
                        }}
                        aria-labelledby="dropdownMenuLink"
                      >
                        <NavLink
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          style={{
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                          }}
                          to="/careers"
                        >
                          Careers
                        </NavLink>
                        <Link
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          to="/login"
                        >
                          Login
                        </Link>
                        <NavLink
                          onClick={() => setIsOpen(false)}
                          className="dropdown-item"
                          style={{ borderRadius: "16px" }}
                          to="/signup"
                        >
                          Signup
                        </NavLink>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BlackNavbar;
