import React, { useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import headerimage from "../assests/headerimage.jpg";
import VendorForget from "../API/ForgetPassword/VendorForget";

const VendorForgetPassword = () => {
  const initialstate = {
    email: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  const { email } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  return (
    <div>
      <BlackNavbar />
      <div
        className="p-1 text-center bg-image  aboutusimage img-fluid col-12 pb-5 "
        style={{ backgroundImage: `url(${headerimage})` }}
      ></div>
      <div className=" col-lg-6 col-md-9 col-11 mxAuto bg-white  loginform">
        <h1 className="text-center pt-5 pb-4">Forget Password</h1>
        <hr className="col-11 mxAuto mb-4"></hr>
        <form>
          <div className="col-11 mxAuto ">
            <input
              type="email"
              className="col-12 bordercolor loginemail fillcolor pl-4 rounded-top"
              placeholder="Username or Email Address"
              required
              name="email"
              value={email}
              onChange={onHandleChange}
            />
          </div>
          <div className="form-group col-11 mxAuto pt-3">
            <button
              type="button"
              className="signupbtn mt-3"
              onClick={() => VendorForget(email)}
            >
              Forget Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorForgetPassword;
