import { message } from "antd";
import axios from "axios";
import $ from "jquery";

const Gender = (gender) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/gender/` +
    localStorage.getItem("venderId");
  axios
    .put(URL, {
      gender: gender,
    })
    .then((res) => {
      if (res.data.success) {
        message.success("Gender updated successfully");
        $("#editgender").removeClass("d-none");
        $("#savegender").addClass("d-none");
        $("#gender").prop("disabled", true);
      } else {
        message.success(res.data.message);
      }
    });
};

export default Gender;
