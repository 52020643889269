import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import SaloonMapView from "./SubComponents/SaloonMapView";
import $ from "jquery";
import crossicon from "../assests/crossicon.png";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import StateLiveLocation from "./LiveLocation";
import { Switch, Spin } from "antd";

const StatesListing = (props) => {
  const { state } = useParams();
  const initialstate = {
    detail: "",
    allVendor: [],
    services: "",
    lat: "",
    lng: "",
    typeofVendor: "",
    instant: false,
    wifi: false,
    travel: false,
    update: false,
  };
  const [FormData, setFormData] = useState(initialstate);
  const { allVendor, instant, wifi, travel, typeofVendor, update } = FormData;
  // SALOON DATA
  const Saloons = () => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor_by_city/` +
      state;

    axios
      .get(link)
      .then((response) => {
        if (response.data.success === true) {
          setFormData({
            ...FormData,
            allVendor: response.data.data,
            update: true,
          });
        } else if (response.data.success === false) {
          setFormData({
            ...FormData,
            update: true,
          });
        }
      })

      .catch(function (error) {});
  };

  useEffect(() => {
    Saloons();
    // eslint-disable-next-line
  }, []);

  function onDepositChange(checked) {
    if (checked === true) {
      setFormData({
        ...FormData,
        typeofVendor: "deposit",
      });
    }
    if (checked === false) {
      setFormData({
        ...FormData,
        typeofVendor: "",
      });
    }
  }

  function onInstantChange(checked) {
    setFormData({
      ...FormData,
      instant: checked,
    });
  }

  function onWifiChange(checked) {
    setFormData({
      ...FormData,
      wifi: checked,
    });
  }

  function onTravelChange(checked) {
    setFormData({
      ...FormData,
      travel: checked,
    });
  }
  // eslint-disable-next-line
  const RenderSaloonMapViewData = allVendor.map((saloonmapview) => {
    // eslint-disable-next-line
    if (wifi == false || saloonmapview.wifi == wifi) {
      // eslint-disable-next-line
      if (instant == false || saloonmapview.instant_booking == instant) {
        // eslint-disable-next-line
        if (
          typeofVendor !== "deposit" ||
          saloonmapview.payment_type === typeofVendor
        ) {
          // eslint-disable-next-line
          if (travel == false || saloonmapview.trevel_to_me == travel) {
            return (
              <>
                <SaloonMapView
                  key={saloonmapview._id}
                  saloonmapview={saloonmapview}
                ></SaloonMapView>
              </>
            );
          }
        }
      }
    }
  });

  const classfunction = () => {
    $(".data").addClass("d-none");
  };

  const showMap = (e) => {
    var isExpanded = $(e.target).hasClass("showMap");
    if (isExpanded) {
      $(".maps").addClass("d-none");
      $(".mapButton").addClass("hideMap");
      $(".mapButton").removeClass("showMap");
      $(".mapButton").html("Show Map");
    } else if (!isExpanded) {
      $(".maps").removeClass("d-none");
      $(".mapButton").addClass("showMap");
      $(".mapButton").removeClass("hideMap");
      $(".mapButton").html("Hide Map");
    }
  };

  if (update !== true) {
    return <Spin size="large" />;
  } else if (update === true) {
    return (
      <>
        <BlackNavbar />
        <div className="row">
          <div className="col-lg-6 col-11 mxAuto PadBottom ">
            <div className="row  max-auto">
              <div className="col-6 ">
                <p className="px-0 Bold">Vendor Type Filter</p>
                <div className=" row">
                  <p className="px-0 mr-auto">Deposit Payment</p>
                  <Switch
                    size="small"
                    onChange={onDepositChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Travel To Me</p>
                  <Switch
                    size="small"
                    onChange={onTravelChange}
                    className="w-auto"
                  />
                </div>
              </div>

              <div className="col-6">
                <p className="px-0 Bold">Other Filters</p>
                <div className=" row">
                  <p className="px-0 mr-auto">Instant Booking</p>
                  <Switch
                    size="small"
                    onChange={onInstantChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Wifi</p>
                  <Switch
                    size="small"
                    onChange={onWifiChange}
                    className="w-auto"
                  />
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="row  m-0 p-0 mt-3 data mapscroll col-md-12 col-lg-12">
              {allVendor.length < 1 ? (
                <h2 className="text-center">
                  No Professional Available Right Now for {state}
                </h2>
              ) : (
                RenderSaloonMapViewData
              )}
            </div>

            <button
              className="btn Orange White d-lg-none mapButton showMap mt-5"
              type="button"
              onClick={showMap}
            >
              Show Map
            </button>
          </div>
          <div className="col-lg-5 col-10 mxAuto crossimg maps d-none d-lg-block">
            <StateLiveLocation
              store={allVendor}
              wifi={wifi}
              instant={instant}
              travel={travel}
              typeofVendor={typeofVendor}
              state={props.history.location.state}
              originLat={
                allVendor.length !== 0
                  ? allVendor[0].lat
                  : props?.history?.location?.state?.coordinates?.lati
              }
              originLng={
                allVendor.length !== 0
                  ? allVendor[0].lng
                  : props?.history?.location?.state?.coordinates?.long
              }
            />
            <button className="btn d-none d-lg-block">
              <NavLink
                to={{
                  pathname: "/Saloonlist/" + state,
                  state: {
                    allvendor: allVendor,
                  },
                }}
              >
                <img
                  src={crossicon}
                  id="1"
                  alt=""
                  className="border-0 "
                  onClick={classfunction}
                ></img>
              </NavLink>
            </button>
          </div>
        </div>
      </>
    );
  }
};

export default StatesListing;
