import React from "react";
import "./index.css";
import App from "./App";
import "antd/dist/antd.min.css";
import { BrowserRouter } from "react-router-dom";
import TransparentLayerProvider from "./store/TransparentLayerProvider";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <>
    <TransparentLayerProvider>
      <BrowserRouter>
      <Toaster />
        <App />
      </BrowserRouter>
    </TransparentLayerProvider>
  </>
);
