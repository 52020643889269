import { message } from "antd";
import axios from "axios";
const VendorForget = (email) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/vendor/forget_password`;
  axios
    .post(URL, {
      email: email,
    })
    .then((res) => {
      if (res.data.success) {
        message.success(res.data.message);
        window.location = "/vendorlogin";
      } else {
        message.error(res.data.message);
      }
    });
};

export default VendorForget;
