import { message } from "antd";
import axios from "axios";
const ChatUser = (vendor_id, user_id, message1, getMessage) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/message/user/` +
    user_id +
    `/` +
    vendor_id;
  axios
    .post(URL, {
      user_id: user_id,
      vendor_id: vendor_id,
      message: message1,
    })
    .then((res) => {
      if (res.data.success) {
        getMessage();
      } else {
        message.error(res.data.message);
      }
    });
};

export default ChatUser;
