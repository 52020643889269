import { CheckCircleOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BlackNavbar from "../../SubComponents/BlackNavbar";
import confirmed from "../../../assests/confirmed.svg";
import axios from "axios";
import VendorDeactivate from "../../../API/Deactivate/VendorDeactivate";
import { useHistory } from "react-router-dom";
const Deactivate = () => {
  let vendor_id = localStorage.getItem("venderId");
  const history = useHistory();
  const initialstate = {
    email: "",
    password: "",
  };
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [FormData, setFormData] = useState(initialstate);
  const { email, password } = FormData;
  useEffect(() => {
    const link =
      `${process.env.REACT_APP_BASE_URL}/api/vendor/get_vendor/` + vendor_id;
    axios.get(link).then((res) => {
      if (res.data.success) {
        initialstate.email = res.data.data.email;
      }
      setFormData({
        ...FormData,
      });
    });
    // eslint-disable-next-line
  }, []);
  const move = () => {
    window.location = "/";
  };
  return (
    <div>
      <BlackNavbar />
      <div className="maxWidth mxAuto">
        <div className="col-11 mxAuto">
          <p className="col-lg-4 col-md-9 mxAuto pt-3">
            <span className="orangecolor">1. Select Reason &gt; </span>
            <span className="orangecolor pl-3">2. Confirm &gt;</span>
            <span className="Black pl-3">3. Done</span>
          </p>
          <div className="col-9 mxAuto">
            <Progress percent={80} status="active" showInfo={false} />
          </div>
          <div className="col-lg-4 col-md-9 col-12 mxAuto pt-5">
            <h1 className="font39">Deactivate Account ?</h1>
            <p>{email}</p>
            <div className="row my-auto ">
              <div className="col-1">
                <CheckCircleOutlined />
              </div>
              <div className="col-10">
                {" "}
                <p>
                  The profile and listings associated with this account will
                  disappear.
                </p>
              </div>
            </div>
            <hr></hr>

            <div className="row my-auto">
              <div className="col-1">
                <CheckCircleOutlined />
              </div>
              <div className="col-10">
                {" "}
                <p>
                  You won’t be able to access the account info or past
                  reservations.
                </p>
              </div>
            </div>

            <hr></hr>
            <div className="row my-auto">
              <NavLink to="/selectreason" className="Black mr-auto">
                &#60; Back
              </NavLink>
              <button
                className="btn Orange White ml-auto "
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Deactivate Account
              </button>
              <div className="col-12 justify-content-center pb-5">
                <div
                  className="modal fade "
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog " role="document">
                    <div className="modal-content ">
                      <img
                        src={confirmed}
                        alt=""
                        className="w-25 mxAuto pt-3 pb-0"
                      ></img>
                      <div className="modal-body ">
                        <input
                          type="email"
                          id="email"
                          placeholder="Email"
                          className="form-control mb-3"
                          value={email}
                          onChange={(e) =>
                            setFormData({ ...FormData, email: e.target.value })
                          }
                        />
                        <input
                          type="password"
                          id="password"
                          placeholder="Password"
                          className="form-control mb-3"
                          value={password}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              password: e.target.value,
                            })
                          }
                        />
                        <button
                          type="button"
                          onClick={async () => {
                            const response = await VendorDeactivate(
                              email,
                              password,
                              vendor_id
                            );
                            if (response.success) {
                              setIsSuccessModalOpen(true);
                            }
                          }}
                          className="btn Orange White p-2 w-50 d-block mxAuto Radius_10"
                          data-dismiss="modal"
                        >
                          Deactivate my account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${isSuccessModalOpen ? "show" : ""}`}
        id="successModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="successModalLabel"
        aria-hidden="true"
        style={{ display: isSuccessModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog " role="document">
          <div className="modal-content ">
            <div className="modal-body ">
              <h5>Account deactivated successfully</h5>
              <button
                type="button"
                onClick={() => {
                  setIsSuccessModalOpen(false);
                  history.push("/");
                }}
                className="btn Orange White p-2 w-50 d-block mxAuto Radius_10"
                data-dismiss="modal"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deactivate;
