import { PayPalButton } from "react-paypal-button-v2";

const Payout = (props) => {
  return (
    <>
      <PayPalButton
        shippingPreference="NO_SHIPPING"
        amount={props.total_bill}
        options={{
          clientId:
            //Sandbox Client Id
            // "AQwi6Cq1EOSZPqvRS-MfGEp3_ueeuw31pisX9CSDebFWCeSRLQdcFVfxv4X8H4e3cRWfiDgeCm3m1Wwb",

            // Live Client Id
            "AUnWuK2XjcW81GCMg087bxVOOgCbtUzt_66T1VIumghEehYjAmnPUY6p6WtAu1w_au_Skmj8XTbXVzfU",
          currency: "USD",
        }}
        onSuccess={(details, data) => {
          console.log("paypal res=>",details,data)
          props.book(
            details?.payer?.email_address,
            details?.purchase_units[0]?.payments?.captures[0]?.id,
            { details, data }
          );
        }}
      />
    </>
  );
};
export default Payout;
