import { message } from "antd";
import axios from "axios";

const VendorDeactivate = async (email, password, vendor_id) => {
  const URL =
    `${process.env.REACT_APP_BASE_URL}/api/vendor/deactivate_account/` +
    vendor_id;
  const response = await axios.post(URL, {
    vendor_id: vendor_id,
    email: email,
    password: password,
  });

  if (response.data.success) {
    message.success(response.data.message);
    localStorage.clear();
  } else {
    message.error(response.data.message);
  }

  return response.data;
};

export default VendorDeactivate;
