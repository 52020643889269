import React from "react";
import { Redirect } from "react-router-dom";
import UserLinks from "./UserLinks";
import VendorLinks from "./VendorLinks";

const LandingScreenforCheck = () => {
  const UserType = localStorage.getItem("type");
  if (UserType === "user") {
    return (
      <>
        <UserLinks />
      </>
    );
  } else if (UserType === "vendor") {
    return (
      <>
        <VendorLinks />
      </>
    );
  } else {
    return (
      <>
        {localStorage.clear()}
        <Redirect to="/" />
      </>
    );
  }
};

export default LandingScreenforCheck;
