import React, { useEffect, useState } from "react";
import BlackNavbar from "../SubComponents/BlackNavbar";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import UserLoginSecurity from "../../API/Security/UserLoginSecurity";
import { message } from "antd";
const UserSecurity = () => {
  const initialstate = {
    current_password: "",
    password: "",
    confirmPwd: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  const { current_password, password, confirmPwd } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };
  const editPwd = () => {
    $("#pwd").removeClass("d-none");
    $("#updatePwd").addClass("d-none");
    $("#confirmPwd").prop("disabled", false);
    $("#current_password").prop("disabled", false);
    $("#password").prop("disabled", false);
  };
  const cancelPwd = () => {
    $("#pwd").addClass("d-none");
    $("#updatePwd").removeClass("d-none");
    $("#confirmPwd").prop("disabled", true);
    $("#current_password").prop("disabled", true);
    $("#password").prop("disabled", true);
    setFormData({
      ...FormData,
      current_password: "",
      password: "",
      confirmPwd: "",
    });
  };
  useEffect(() => {
    $("#confirmPwd").prop("disabled", true);
    $("#current_password").prop("disabled", true);
    $("#password").prop("disabled", true);
  }, []);

  const validation = () => {
    if (current_password !== "") {
      if (password !== "") {
        if (confirmPwd !== "") {
          // eslint-disable-next-line
          if (confirmPwd == password) {
            UserLoginSecurity(current_password, password, confirmPwd);
          } else if (confirmPwd !== password) {
            setFormData({
              ...FormData,
              error: "New Password doesnot match",
            });
            message.error("New Password doesnot match");
          }
        } else if (confirmPwd === "") {
          setFormData({
            ...FormData,
            error: "Confirm your Password",
          });
          message.error("Confirm your Password");
        }
      } else {
        setFormData({
          ...FormData,
          error: "New Password is Required",
        });
        message.error("New Password is Required");
      }
    } else if (current_password === "") {
      setFormData({
        ...FormData,
        error: "Current Password is Required",
      });
      message.error("Current Password is Required");
    }
  };
  return (
    <div>
      <BlackNavbar />
      <div className="col-12 Orange border196 pt-5">
        <div className="col-11 mxAuto maxWidth">
          <p className="White pt-5  font17">
            <NavLink to="/account" className="White">
              Account
            </NavLink>{" "}
            &gt; Security
          </p>
          <h1 className="White  pb-3">Login &#38; Security</h1>
        </div>
      </div>

      <div className="col-11 mxAuto maxWidth">
        <div className=" col-md-5 col-12 pt-5">
          <h3 className="">Login</h3>
          <div className="">
            <div className="row">
              <h4 className="mr-auto">Password</h4>
              <button
                type="button"
                className="btn orangecolor ml-auto"
                id="updatePwd"
                onClick={editPwd}
              >
                Update
              </button>
            </div>
            <input
              type="password"
              id="current_password"
              className="col-12 fillcolor bordercolor rounded-0 height48 overflow-hidden "
              placeholder="Current Password"
              name="current_password"
              value={current_password}
              onChange={onHandleChange}
            ></input>
          </div>
          <div className="form-group pt-3">
            <input
              type="password"
              id="password"
              className="col-12 fillcolor bordercolor rounded-0 height48 overflow-hidden "
              placeholder="New Password"
              name="password"
              value={password}
              onChange={onHandleChange}
            ></input>
          </div>
          <div className="form-group pt-2">
            <input
              type="password"
              id="confirmPwd"
              className="col-12 fillcolor bordercolor rounded-0 height48 overflow-hidden "
              placeholder="Confirm Password"
              name="confirmPwd"
              value={confirmPwd}
              onChange={onHandleChange}
            ></input>
          </div>
          <div className="col-12 pt-3 d-none" id="pwd">
            <button
              type="button"
              className="btn Orange White"
              onClick={validation}
            >
              Save
            </button>
            <button
              type="button"
              className="btn float-right orangecolor"
              onClick={cancelPwd}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSecurity;
