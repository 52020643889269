import { message } from "antd";
import axios from "axios";
const Contact = (
  first_name,
  last_name,
  phone,
  message1,
  email,
  initialstate,
  setFormData
) => {
  const URL = `${process.env.REACT_APP_BASE_URL}/api/user/contactUs`;
  axios
    .post(URL, {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      message: message1,
      email: email,
    })
    .then((res) => {
      if (res.data.success) {
        message.success(res.data.message);
        setFormData(initialstate);
        // window.location = "/contactus";
      } else {
        message.error(res.data.message.message);
      }
    });
};

export default Contact;
