import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";

import Hiring from "../API/Career/Hiring";
import { message } from "antd";
const Careers = () => {
  const initialstate = {
    name: "",
    email: "",
    message1: "",
  };
  const [FormData, setFormData] = useState(initialstate);
  const { message1, name, email } = FormData;
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const validation = () => {
    if (name !== "") {
      if (email !== "") {
        if (email.includes("@") && email.includes(".")) {
          if (message1 !== "") {
            Hiring(name, email, message1);
          } else if (message1 === "") {
            setFormData({
              ...FormData,
              error: "Message is Required",
            });
            message.error("Message is Required");
          }
        } else {
          setFormData({
            ...FormData,
            error: "Email is not valid",
          });
          message.error("Email is not valid");
        }
      } else if (email === "") {
        setFormData({
          ...FormData,
          error: "Email is Required",
        });
        message.error("Email is Required");
      }
    } else if (name === "") {
      setFormData({
        ...FormData,
        error: "Name is Required",
      });
      message.error("Name is Required");
    }
  };
  return (
    <div>
      <BlackNavbar />
      <div className="col-11 mxAuto maxWidth">
        <div className=" col-lg-6 col-md-9 row col-12 paddingTop_5">
          <div className=" col-12   ">
            <h1 className="mb-0">Join Blackbooking for work</h1>
            <p>Recruiting fresh talent is our top priority.</p>
            <p>
              If you fill out the form below, we will contact you as soon as
              possible.
            </p>
            <form>
              <div className="form-group">
                <h4>Your Name</h4>
                <input
                  type="text"
                  className="col-12 fillcolor bordercolor rounded-0 p-2 overflow-hidden "
                  placeholder="Name"
                  name="name"
                  value={name}
                  onChange={onHandleChange}
                ></input>
              </div>

              <div className="form-group">
                <h4>Work Email Address</h4>
                <input
                  type="text"
                  className="col-12 fillcolor p-2 bordercolor  rounded-0 "
                  placeholder="Email"
                  name="email"
                  value={email}
                  onChange={onHandleChange}
                ></input>
              </div>

              <div className="form-group">
                <h4>Proposal</h4>
                <textarea
                  className="col-12 ProfileArea fillcolor bordercolor rounded-0 focusNone"
                  placeholder="Message"
                  rows="10"
                  name="message1"
                  value={message1}
                  onChange={onHandleChange}
                ></textarea>
              </div>

              <span className="float-left">
                <button
                  type="button"
                  className="btn Orange White text-center rounded-0"
                  onClick={validation}
                >
                  Submit
                </button>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
