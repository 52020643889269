import React, { useEffect, useState } from "react";
import BlackNavbar from "./SubComponents/BlackNavbar";
import BusinessListingComponent from "./SubComponents/BusinessListingComponent";

import { Pagination, Switch, Spin } from "antd";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import placesData from "./data/placesData";

const BuisnessListingwithoutMap = (props) => {
  const initialstate = {
    detail: "",
    allVendor: [],
    displayedVendors: [],
    updated: false,
    typeofVendor: "",
    instant: false,
    wifi: false,
    travel: false,
    postPerPage: 16,
  };

  const [FormData, setFormData] = useState(initialstate);
  const {
    allVendor,
    displayedVendors,
    postPerPage,
    updated,
    typeofVendor,
    instant,
    wifi,
    travel,
  } = FormData;
  const { state } = useParams();
  const [place, setPlace] = useState("");

  useEffect(() => {
    setPlace(placesData.find((place) => place.name === state));
  }, [state]);

  function onDepositChange(checked) {
    if (checked === true) {
      setFormData({
        ...FormData,
        typeofVendor: "deposit",
      });
    }
    if (checked === false) {
      setFormData({
        ...FormData,
        typeofVendor: "",
      });
    }
  }

  function onInstantChange(checked) {
    setFormData({
      ...FormData,
      instant: checked,
    });
  }

  function onWifiChange(checked) {
    setFormData({
      ...FormData,
      wifi: checked,
    });
  }

  function onTravelChange(checked) {
    setFormData({
      ...FormData,
      travel: checked,
    });
  }

  useEffect(() => {
    setFormData({
      ...FormData,
      allVendor: props.location.state.allvendor,
      displayedVendors: props.location.state.allvendor.slice(0, postPerPage),
      updated: true,
    });
    // eslint-disable-next-line
  }, []);
  function onChange(pageNumber) {
    const indexOfLastPage = pageNumber * postPerPage;
    const indexOfFirstPost = indexOfLastPage - postPerPage;
    const currentPost = props.location.state.allvendor.slice(
      indexOfFirstPost,
      indexOfLastPage
    );

    setFormData({
      ...FormData,
      displayedVendors: currentPost,
    });
  }

  // eslint-disable-next-line
  const RenderBusinessListing = displayedVendors.map((businesslisting) => {
    // eslint-disable-next-line
    if (wifi == false || businesslisting.wifi == wifi) {
      // eslint-disable-next-line
      if (instant == false || businesslisting.instant_booking == instant) {
        // eslint-disable-next-line
        if (
          typeofVendor !== "deposit" ||
          businesslisting.payment_type === typeofVendor
        ) {
          // eslint-disable-next-line
          if (travel == false || businesslisting.trevel_to_me == travel) {
            return (
              <>
                <BusinessListingComponent
                  key={businesslisting._id}
                  businesslisting={businesslisting}
                ></BusinessListingComponent>
              </>
            );
          }
        }
      }
    }
  });
  if (updated === true) {
    return (
      <>
        <BlackNavbar />
        <div className="col-md-12 mxAuto col-12 ">
          <div className=" mxAuto maxWidth ">
            <div className="row max-auto">
              <div className="col-lg-4 col-12">
                <p className="px-0 Bold">Vendor Type Filter</p>
                <div className=" row">
                  <p className="px-0 mr-auto">Deposit Payment</p>
                  <Switch
                    size="small"
                    onChange={onDepositChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Travel To Me</p>
                  <Switch
                    size="small"
                    onChange={onTravelChange}
                    className="w-auto"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-12">
                <p className="px-0 Bold">Other Filters</p>
                <div className=" row">
                  <p className="px-0 mr-auto">Instant Booking</p>
                  <Switch
                    size="small"
                    onChange={onInstantChange}
                    className="w-auto"
                  />
                </div>
                <div className="row">
                  <p className="px-0 mr-auto">Wifi</p>
                  <Switch
                    size="small"
                    onChange={onWifiChange}
                    className="w-auto"
                  />
                </div>
              </div>
              <div className="col-4 ml-auto">
                <button className="float-right py-0 mapviewbtn">
                  <NavLink
                    className="White"
                    to={{
                      pathname: "/vender/" + state,
                      state: place,
                    }}
                  >
                    Map View
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className=" maxWidth mxAuto PadBottom">
            <div className="row  m-0 p-0 mt-3">
              {allVendor.length !== 0 ? (
                RenderBusinessListing
              ) : (
                <p style={{ minHeight: "500px" }}>
                  We are sorry but you can search something else
                </p>
              )}
            </div>
            {allVendor.length / postPerPage > 1 ? (
              <div className="pt-5">
                <Pagination
                  defaultCurrent={1}
                  total={(allVendor.length / postPerPage) * 10}
                  onChange={onChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  } else if (updated === false) {
    return <Spin size="large" />;
  }
};

export default BuisnessListingwithoutMap;
